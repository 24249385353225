import React from 'react'
import * as rn from 'react-native'

import * as c from '../../common'
import * as r from '../../react-utils'

import * as gs from '../gStyles'

import Labeled from './Labeled'

export interface RadioProps {
  disabled?: boolean
  grow?: boolean
  guideline?: string
  guidelineType?: c.Guideline['type']
  hide?: boolean
  label?: false | string
  labelShowAsterisk?: boolean
  multiple?: boolean
  on: c.Surface
  onChange: (newValue: string) => void
  opts: c.Opts
  required?: boolean
  value?: string
}

export default React.memo<RadioProps>(function Radio({
  disabled,
  grow,
  guideline,
  guidelineType,
  hide,
  label,
  labelShowAsterisk,
  multiple,
  on,
  onChange,
  opts,
  required,
  value: selectedValue = ''
}) {
  const styles = gs.useThemedStyleSheet(themedStyles, on)

  const selectedNotInOpts = React.useMemo(
    () =>
      !multiple &&
      !!selectedValue &&
      !opts.find((o) => o.value === selectedValue),
    [multiple, opts, selectedValue]
  )

  const handlePressOpt = React.useCallback(
    (optValue: string): void => {
      if (multiple) {
        const selectedValues =
          selectedValue === '' ? c.emptyArr : selectedValue.split(' / ')
        const alreadySelected = selectedValues.includes(optValue)
        if (alreadySelected) {
          onChange?.(selectedValues.filter((v) => v !== optValue).join(' / '))
        } else {
          onChange?.(selectedValues.concat(optValue).sort().join(' / '))
        }
      } else {
        onChange?.(optValue)
      }
    },
    [multiple, onChange, selectedValue]
  )

  const renderOption = React.useCallback(
    (item: c.Opt, index: number, opts) => {
      return (
        <Option
          disabled={!!disabled || selectedNotInOpts}
          isFirst={index === 0}
          isLast={index === opts.length - 1}
          isMultiple={!!multiple}
          isSelected={
            multiple
              ? Boolean(selectedValue?.split(' / ').includes(item.value))
              : selectedValue === item.value
          }
          label={item.label}
          key={item.value}
          on={on}
          onPress={handlePressOpt}
          value={item.value}
        />
      )
    },
    [selectedNotInOpts, disabled, handlePressOpt, multiple, on, selectedValue]
  )

  const rootEl = (
    <rn.View
      style={(() => {
        if (!grow && !label) return hide ? styles.rootHidden : styles.root
        return hide ? styles.rootGrowHidden : styles.rootGrow
      })()}
    >
      {opts.map(renderOption)}
    </rn.View>
  )

  if (label) {
    return (
      <Labeled
        disabled={disabled}
        grow={grow}
        guideline={
          guideline ||
          (required && !selectedValue && !disabled && 'Select an option')
        }
        guidelineType={guideline ? guidelineType : 'warning'}
        label={label}
        on={on}
        showAsterisk={labelShowAsterisk}
      >
        {rootEl}
      </Labeled>
    )
  }

  return rootEl
})

interface OptionProps {
  readonly disabled: boolean
  readonly isFirst: boolean
  readonly isLast: boolean
  readonly isMultiple: boolean
  readonly isSelected: boolean
  readonly label: string
  readonly on: c.Surface
  readonly onPress: (value: c.Opt['value']) => void
  readonly value: c.Opt['value']
}

const Option = React.memo<OptionProps>(
  ({
    disabled,
    isFirst,
    isLast,
    isMultiple,
    isSelected,
    label,
    on,
    onPress,
    value
  }) => {
    //#region styling
    const t = r.useTheme()
    const styles = gs.useThemedStyleSheet(themedStyles, on)

    const [textWidth, setTextWidth] = React.useState(0)
    const handleLayoutText = React.useCallback(
      ({
        nativeEvent: {
          layout: { width }
        }
      }: rn.LayoutChangeEvent): void => {
        setTextWidth(width)
      },
      []
    )

    const lineStyle = React.useMemo(
      (): rn.ViewStyle => ({
        backgroundColor: t.input[on].highlight,
        bottom: -4,
        height: rn.StyleSheet.hairlineWidth,
        left: 0,
        position: 'absolute',
        width: textWidth
      }),
      [on, t.input, textWidth]
    )

    /**
     * TODO: Good place to test useMemo() vs without. The number of deps is
     * lower than the times they are accessed, but the amount of comparisons is
     * close. Update January 2025: FWIW the number of deps was greatly reduced.
     */
    const optStyle = React.useMemo(() => {
      if (disabled && isFirst && isLast) return styles.optDisabledOnly
      if (disabled && isFirst) return styles.optDisabledFirst
      if (disabled && isLast) return styles.optDisabledLast
      if (disabled) return styles.optDisabled

      if (isFirst && isLast) return styles.optOnly
      if (isFirst) return styles.optFirst
      if (isLast) return styles.optLast
      return styles.opt
    }, [
      disabled,
      isFirst,
      isLast,
      styles.optDisabledOnly,
      styles.optDisabledFirst,
      styles.optDisabledLast,
      styles.optDisabled,
      styles.optOnly,
      styles.optFirst,
      styles.optLast,
      styles.opt
    ])
    // const isFirst = i === 0
    // const isLast = i === totalOpts.length - 1
    //#endregion styling
    //#region global
    //#endregion global
    //#region local
    const handlePress = React.useCallback(
      () => void onPress(value),
      [value, onPress]
    )

    //#endregion local

    return (
      <rn.TouchableOpacity
        disabled={disabled}
        onPress={handlePress}
        style={optStyle}
      >
        <rn.View>
          {isMultiple ? (
            <rn.View style={styles.circle}>
              {isSelected && <rn.View style={styles.dot} />}
            </rn.View>
          ) : (
            isSelected && <rn.View style={lineStyle} />
          )}

          <rn.Text
            onLayout={handleLayoutText}
            style={disabled ? styles.optDisabledText : styles.optText}
          >
            {label}
          </rn.Text>
        </rn.View>
      </rn.TouchableOpacity>
    )
  }
)

const circleSize = 12
const dotSize = 8

const themedStyles = gs.ThemedStyleSheet.create((t, on) => {
  const first = {
    borderBottomLeftRadius: t.borderRadius,
    borderTopLeftRadius: t.borderRadius
  }
  const last = {
    borderBottomRightRadius: t.borderRadius,
    borderTopRightRadius: t.borderRadius
  }
  const only = { ...first, ...last }

  const opt = {
    ...gs.deadCenterGrow,
    backgroundColor: t.input[on].backgroundColor,
    borderColor: t.input[on].borderColor,
    // Makes each opt same size regardless of content inside
    flexBasis: 0,
    flexDirection: 'row',
    paddingVertical: t.input[on].paddingVertical
  } as const
  const optText = {
    color: t.input[on].color,
    fontFamily: t[on].fontFamily,
    fontSize: 14,
    fontWeight: '400',
    textAlign: 'center',
    textAlignVertical: 'center'
  } as const
  const optFirst = { ...opt, ...first }
  const optLast = { ...opt, ...last }
  const optOnly = { ...opt, ...only }

  const optDisabled = { ...opt, opacity: 0.5 }
  const optDisabledFirst = { ...optDisabled, ...first }
  const optDisabledLast = { ...optDisabled, ...last }
  const optDisabledOnly = { ...optDisabled, ...only }

  return {
    circle: {
      ...gs.deadCenter,
      backgroundColor: t[on].backgroundColor,
      borderColor: t[on].borderColor,
      borderRadius: circleSize / 2,
      borderWidth: rn.StyleSheet.hairlineWidth,
      bottom: -12,
      height: circleSize,
      left: '50%',
      position: 'absolute',
      transform: [{ translateX: -(circleSize / 2) }],
      width: circleSize
    },
    disabled: { backgroundColor: t[on].opaque },
    dot: {
      backgroundColor: t.input[on].highlight,
      borderRadius: dotSize / 2,
      height: dotSize,
      width: dotSize
    },
    opt,
    optFirst,
    optLast,
    optOnly,
    optText,
    optDisabled,
    optDisabledText: { ...optText, opacity: 0.5 },
    optDisabledFirst,
    optDisabledLast,
    optDisabledOnly,
    rootGrow: { ...gs.inputGrowNew, ...gs.row },
    rootGrowHidden: { ...gs.inputGrowNewHidden, ...gs.row },
    root: { ...gs.inputShrinkNew, ...gs.row },
    rootHidden: { ...gs.inputShrinkNewHidden, ...gs.row }
  }
})
