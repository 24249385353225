/**
 * @file Defines all types to be used throughout the app. There's also
 * validators, functions that take an object of unknown type and check whether
 * that object does conform to the expected type or not.
 */

import moment from 'moment'
import uniqBy from 'lodash.uniqby'
import { v1 as uuid } from 'uuid'

import * as env from '../env'
import * as u from '../utils'

/**
 * Price Interface
 */

export interface Price {
  label: string
  value: number
}

export interface CityPrices {
  label?: string
  list: Price[]
}

export interface StatePrices {
  pricesByCity: CityPrices[]
}

export interface SalePrices {
  california: StatePrices
  nevada: CityPrices
}

export type SaleTag = Readonly<Record<CustomerField, SalePrices>>

export const boolean = ((val: unknown) =>
  typeof val === 'boolean' || val === '') as unknown as boolean
export const number = ((val: unknown) =>
  typeof val === 'number') as unknown as number
export const string = ((val: unknown) =>
  typeof val === 'string') as unknown as string
export const YesNo = ((val: unknown) =>
  val === 'yes' || val === 'no') as unknown as 'yes' | 'no'
export const YesNoEmpty = ((val: unknown) =>
  val === 'yes' || val === 'no' || val === '') as unknown as 'yes' | 'no' | ''
export const Bit = ((val: unknown) =>
  val === 0 || val === 1 || val === '') as unknown as 0 | 1
/**
 * Special case validator, only really validates at read-time, after
 * sanitizing, and not at write time, like other validators, however, we are
 * only using this for `has_touched_customer_cost` which is not written into
 * outside of updateCustomer().
 */
export const StrToPrimitive = ((val: unknown) => {
  try {
    if (typeof val !== 'object' || val === null) {
      return false
    }
    for (const objVal of u.values(val)) {
      const isBool = boolean as unknown as (value: unknown) => boolean
      const isStr = string as unknown as (value: unknown) => boolean
      const isNumber = string as unknown as (value: unknown) => boolean

      const isValid = isBool(objVal) || isStr(objVal) || isNumber(objVal)

      if (!isValid) {
        return false
      }
    }
    return true
  } catch (e) {
    u.log(`strToPrimitive() -> `, {
      e,
      val
    })
    return false
  }
}) as unknown as Record<string, u.Primitive>

export const sanitizeBoolean = (val: unknown): boolean => {
  if (val === 'no') return false
  if (val === 'yes') return true
  return !!val
}

export const sanitizeNumber = (val: unknown): number => {
  // Coerce empty strings / nulls to 0
  let finalVal = Number(val || 0)
  finalVal = Number.isFinite(val) ? (val as number) : 0
  return finalVal
}

export const sanitizeString = (val: unknown): string => {
  // Coerce falsy values to an empty string
  return String(val || '')
}

export const sanitizeYesNo = (val: unknown): typeof YesNo => {
  // Coerce empty strings / nulls to 'no'
  if (val === 'yes') return 'yes'
  if (val === 'no') return 'no'

  return 'no'
}

export const sanitizeYesNoEmpty = (val: unknown): typeof YesNoEmpty => {
  if (val === 'yes') return 'yes'
  if (val === 'no') return 'no'

  return ''
}

export const sanitizeBit = (val: unknown): typeof Bit => {
  // Coerce empty strings / nulls to 0
  return val ? 1 : 0
}

export const sanitizeStrToPrimitive = (
  val: unknown
): Readonly<Record<string, u.Primitive>> => {
  if (typeof val !== 'string') {
    return u.EMPTY_OBJ
  }

  try {
    const obj = JSON.parse(val)
    if (typeof obj !== 'object' || obj === null) {
      return u.EMPTY_OBJ
    }
    for (const [key, val] of u.entries(obj)) {
      const isPrimitive = ['boolean', 'number', 'string'].includes(typeof val)

      if (!isPrimitive) {
        delete obj[key]
      }
    }
    return obj
  } catch (e) {
    u.log('sanitizeStrToPrimitive()', {
      e,
      val
    })
    return u.EMPTY_OBJ
  }
}

export const fieldTypeToSanitizer = {
  [Bit.toString()]: sanitizeBit,
  [StrToPrimitive.toString()]: sanitizeStrToPrimitive,
  [YesNo.toString()]: sanitizeYesNo,
  [YesNoEmpty.toString()]: sanitizeYesNoEmpty,
  [boolean.toString()]: sanitizeBoolean,
  [number.toString()]: sanitizeNumber,
  [string.toString()]: sanitizeString
} as const

/**
 * Defines in runtime the Customer type for use in validation.
 */
export const CustomerSchema = {
  // #region essentialFields
  has_touched_customer_cost: StrToPrimitive,
  has_touched_cost: StrToPrimitive,
  homeRep: string,
  id: string,
  deleted: boolean,
  keyID: string,
  customer_id_number: string,
  efficiency_updated: string,
  solarCompany: string,
  /**
   * `'Solar'|'HIL'|'RC'`.
   */
  products: string,
  solarEmail: string,
  ssn: string,
  solarRep: string,
  yearHomeBuilt: string,
  yearHomeYearBuilt: string,
  yearly_energy_usage: string,
  yearly_gas_usage: string,
  globalNotes: string,
  /**
   * Generated server-side in firebase using `database.ServerValue.TIMESTAMP`.
   */
  createdAt: number,
  /**
   * Injected by Algolia, similar to key. Defined here to simplify types
   * elsewhere.
   */
  objectID: string,
  company_name: string,
  date: number,
  /**
   * This key is not actually in firebase but it should be added immediately
   * after obtaining the data from it, it is included here so as to only have
   * one type definition.
   */
  key: string,
  /**
   * A direct pointer to the house picture, shown in several places therefore
   * avoiding too many subscriptions to the customer's media.
   */
  owner_house: string,
  /**
   * Equivalent to "last modified". The lower this number, the most recently
   * modified this record is.
   */
  sort_key: number,
  customerAddress: string,
  addressLat: number,
  addressLng: number,
  customerEmail: string,
  customerName: string,
  customerPhone: string,
  customerPhoneAlt: string,
  customerReport: string,
  customer_signature: string,
  customer_initials: string,
  /**
   * This used to be only used for MPU, then we created another one for S-MPU
   * (which is now removed). Since the installation company would of course be
   * the same, we are now making it a main field, and will be shared by all
   * efficiencies.
   */
  main_panel_upgrade_installation_company: string,
  // #endregion essentialFields
  // #region airConditioner
  airConditioner: boolean,
  air_conditioner: YesNo,
  air_conditioner_cost: string,
  air_conditioner_customer_cost: string,
  air_conditioner_extra_notes: string,
  air_conditioner_notes: string,
  air_conditioner_show: Bit,
  air_conditioner_size: string,
  air_conditioner_replace_ducts: YesNo,
  air_conditioner_working: YesNo,
  air_conditioner_any_swamp_cooler: YesNo,
  air_conditioner_current_tons: string,
  air_conditioner_new_tons: string,
  air_conditioner_unit_type: string,
  air_conditioner_tons_how_many: string,
  air_conditioner_furnance: YesNo,
  /**
   * Still running in production, will deprecate.
   */
  air_conditioner_swamp_cooler_have_remove: YesNo,
  air_conditioner_swamp_cooler_use_remove: YesNo,
  air_conditioner_swamp_cooler_use: YesNo,
  air_conditioner_replace_curve: YesNo,
  // #endregion airConditioner
  // #region atticFan
  atticFan: boolean,
  attic_fan: YesNo,
  attic_fan_cost: string,
  attic_fan_customer_cost: string,
  attic_fan_notes: string,
  attic_fan_extra_notes: string,
  attic_fan_quantity: string,
  attic_fan_show: Bit,
  // #endregion atticFan
  // #region atticInsulation
  attic_insulation: YesNo,
  attic_insulation_cost: string,
  atticInsulation: boolean,
  attic_insulation_customer_cost: string,
  attic_insulation_how_much: string,
  attic_insulation_levels_type: string,
  attic_insulation_notes: string,
  attic_insulation_extra_notes: string,
  attic_insulation_show: Bit,
  attic_square_footage: string,
  attic_insulation_type: string,
  // #endregion atticInsulation
  // #region report
  signature: string,
  auditorReport: string,
  auditor_report_edit: string,
  battery: YesNo,
  battery_type: string,
  battery_size: string,
  battery_cost: string,
  battery_customer_cost: string,
  battery_notes: string,
  battery_extra_notes: string,
  battery_show: Bit,

  /**
   * Used in report.
   */
  report_address: string,
  report_amount: string,
  report_app_id: string,
  report_company_signature: string,
  report_company_signature_date: number,
  report_contractor_name: string,
  report_customer_name: string,
  /**
   * The customer name as it appears on the report to be signed.
   */
  c_name: string,
  c_signature: string,
  c_signature_date: number,
  r_signature: string,
  /**
   * Used in report.
   */
  release_liability_date: number,
  release_of_liability_amount: string,
  // #endregion report
  // #region cash
  cash: YesNo,
  cashCheck: boolean,

  cash_options: string, // This is a  object parse a string

  cash_air_conditioner: string,
  cash_artificial_turf: string,
  cash_amount: string,
  cash_irrigation_systems: string,
  cash_heat_pumps: string,
  cash_whole_house_fans_or_ventilation: string,
  cash_efficient_boilers: string,
  cash_geothermal_heat_pumps: string,
  cash_radiant_floors: string,
  cash_water_saving_fixtures: string,
  cash_solar_water_heaters: string,
  cash_on_demand_or_tankless_hot: string,
  cash_windows: string,
  cash_floor_insulation: string,
  cash_efficient_water_heaters: string,
  cash_pool_pumps: string,

  cash_cost: string,
  cash_customer_cost: string,
  cash_notes: string,
  cash_show: Bit,
  // #endregion cash

  // #region custom
  //
  custom_fields: YesNo,
  custom_fields_cost: string,
  custom_fields_customer_cost: string,
  custom_fields_description: string,
  custom_fields_name: string,
  custom_fields_notes: string,
  custom_fields_show: Bit,
  //
  custom_two_fields: YesNo,
  custom_two_fields_cost: string,
  custom_two_fields_customer_cost: string,
  custom_two_fields_description: string,
  custom_two_fields_name: string,
  custom_two_fields_notes: string,
  custom_two_fields_show: Bit,
  //
  custom_three_fields: YesNo,
  custom_three_fields_cost: string,
  custom_three_fields_customer_cost: string,
  custom_three_fields_description: string,
  custom_three_fields_name: string,
  custom_three_fields_notes: string,
  custom_three_fields_show: Bit,
  //
  custom_four_fields: YesNo,
  custom_four_fields_cost: string,
  custom_four_fields_customer_cost: string,
  custom_four_fields_description: string,
  custom_four_fields_name: string,
  custom_four_fields_notes: string,
  custom_four_fields_show: Bit,
  //
  custom_five_fields: YesNo,
  custom_five_fields_cost: string,
  custom_five_fields_customer_cost: string,
  custom_five_fields_description: string,
  custom_five_fields_name: string,
  custom_five_fields_notes: string,
  custom_five_fields_show: Bit,
  //
  custom_six_fields: YesNo,
  custom_six_fields_cost: string,
  custom_six_fields_customer_cost: string,
  custom_six_fields_description: string,
  custom_six_fields_name: string,
  custom_six_fields_notes: string,
  custom_six_fields_show: Bit,
  //
  custom_seven_fields: YesNo,
  custom_seven_fields_cost: string,
  custom_seven_fields_customer_cost: string,
  custom_seven_fields_description: string,
  custom_seven_fields_name: string,
  custom_seven_fields_notes: string,
  custom_seven_fields_show: Bit,
  //
  custom_eight_fields: YesNo,
  custom_eight_fields_cost: string,
  custom_eight_fields_customer_cost: string,
  custom_eight_fields_description: string,
  custom_eight_fields_name: string,
  custom_eight_fields_notes: string,
  custom_eight_fields_show: Bit,
  //
  custom_nine_fields: YesNo,
  custom_nine_fields_cost: string,
  custom_nine_fields_customer_cost: string,
  custom_nine_fields_description: string,
  custom_nine_fields_name: string,
  custom_nine_fields_notes: string,
  custom_nine_fields_show: Bit,
  //
  custom_ten_fields: YesNo,
  custom_ten_fields_cost: string,
  custom_ten_fields_customer_cost: string,
  custom_ten_fields_description: string,
  custom_ten_fields_name: string,
  custom_ten_fields_notes: string,
  custom_ten_fields_show: Bit,
  // #endregion custom

  // #region derate
  derate: YesNo,
  derate_cost: string,
  derate_customer_cost: string,
  derate_notes: string,
  derate_extra_notes: string,
  derate_show: Bit,
  derateShow: boolean,
  // #endregion derate
  // #region ductInsulation
  ductInsulation: boolean,
  duct_insulation: YesNo,
  duct_insulation_cost: string,
  duct_insulation_customer_cost: string,
  duct_insulation_notes: string,
  duct_insulation_show: Bit,
  // #endregion ductInsulation
  // #region ductRepair
  ductRepair: boolean,
  duct_repair: YesNo,
  duct_repair_cost: string,
  duct_repair_customer_cost: string,
  duct_repair_notes: string,
  duct_repair_quantity: string,
  duct_repair_show: Bit,
  // #endregion ductRepair
  // #region ductReplacement
  ductReplacement: boolean,
  duct_replacement: YesNo,
  duct_replacement_qty: string,
  duct_replacement_cost: string,
  duct_replacement_customer_cost: string,
  duct_replacement_notes: string,
  duct_replacement_show: Bit,
  // #endregion ductReplacement
  // #region ductSeal
  ductSeal: boolean,
  duct_seal: YesNo,
  duct_seal_cost: string,
  duct_seal_customer_cost: string,
  duct_seal_notes: string,
  duct_seal_extra_notes: string,
  duct_seal_show: Bit,
  // #endregion ductSeal
  // #region flatRoofPanels
  flat_roof_panels: YesNo,
  flatRoofPanels: boolean,
  flat_roof_panels_show: Bit,
  flat_roof_panels_customer_cost: string,
  flat_roof_panels_cost: string,
  flat_roof_panels_how_many: string,
  flat_roof_panels_notes: string,
  // #endregion flatRoofPanels
  // #region glow
  glow: YesNo,
  glow_price_per_watt: string,
  glow_power_output_mw: string,
  glow_cost: string,
  glow_customer_cost: string,
  glow_notes: string,
  glow_extra_notes: string,
  glow_show: Bit,

  qualify_glow_first_name: string,
  qualify_glow_last_name: string,
  qualify_glow_phone_number: string,
  qualify_glow_email: string,
  qualify_glow_ssn: string,
  qualify_glow_birth_date: number,
  qualify_glow_street: string,
  qualify_glow_city: string,
  qualify_glow_state: string,
  qualify_glow_zip: string,

  incentive_glow_carbon_credits: Bit,
  incentive_glow_cash: Bit,
  incentive_glow_tax_deduction: Bit,
  incentive_glow_federal_tax_credit: Bit,
  incentive_adders_description: string,
  // #endregion glow

  payment_given_customer: string,

  invoiceReport: string,

  crm_id: string,

  // #region miniSplit
  miniSplit: boolean,
  mini_split: YesNo,
  mini_split_cost: string,
  mini_split_customer_cost: string,
  mini_split_ton1: string,
  mini_split_ton2: string,
  mini_split_tons: string,
  mini_split_notes: string,
  mini_split_extra_notes: string,
  mini_split_show: Bit,
  // #endregion miniSplit
  // #region newDucts
  newDucts: boolean,
  new_ducts: YesNo,
  new_ducts_cost: string,
  new_ducts_customer_cost: string,
  new_ducts_description: string,
  new_ducts_extra_notes: string,
  new_ducts_notes: string,
  new_ducts_quantity: string,
  new_ducts_show: Bit,
  new_ducts_replace: YesNo,
  // #endregion newDucts
  // #region newWindows
  newWindows: boolean,
  new_windows: YesNo,
  new_windows_areas: string,
  new_windows_color: string,
  new_windows_cost: string,
  new_windows_grid: YesNo,
  new_windows_sqft_each_window: string,
  new_windows_customer_cost: string,
  new_windows_notes: string,
  new_windows_extra_notes: string,
  new_windows_quantity: string,
  new_windows_show: Bit,
  new_windows_sliding_glass: YesNo,
  new_windows_sliding_glass_size: string,
  /**
   * Actually linear feet. Mistake when initially named.
   */
  new_windows_sliding_glass_sqft: string,
  new_windows_sliding_glass_how_many: string,
  new_windows_california_city: string,
  new_windows_whole_house_custom: string,
  new_windows_sqft: string,
  new_windows_replace: YesNoEmpty,
  new_windows_replace_custom: string,
  // #endregion newWindows
  // #region poolPump
  poolPump: boolean,
  pool_pump: YesNo,
  pool_pump_cost: string,
  pool_pump_custom: string,
  pool_pump_customer_cost: string,
  pool_pump_notes: string,
  pool_pump_show: Bit,
  // #endregion poolPump
  // #region roof
  roof: YesNo,
  roofCheck: boolean,
  roof_california_city: string,
  roof_cost: string,
  roof_fascia_included: YesNo,
  roof_fascia_included_square_footage: string,
  roof_plywood_under_tile: YesNo,
  roof_plywood_replaced: YesNo,
  roof_plywood_replaced_square: string,
  roof_plywood_custom_roughly: string,
  roof_layover_or_tear: string,
  roof_tear_material: string,
  roof_patio_included: YesNo,
  roof_patio_how_many: string,
  roof_customer_cost: string,
  roof_layers_how_many: string,
  roof_square_footage: string,
  roof_extra_notes: string,
  roof_notes: string,
  roof_show: Bit,
  // #endregion roof
  // #region smallSystem
  small_system: YesNo,
  smallSystem: boolean,
  small_system_show: Bit,
  small_system_customer_cost: string,
  small_system_cost: string,
  small_system_notes: string,
  // #endregion smallSystem
  // #region smart_thermostat
  smartThermostat: boolean,
  smart_thermostat: YesNo,
  smart_thermostat_cost: string,
  smart_thermostat_customer_cost: string,
  smart_thermostat_how_many: string,
  smart_thermostat_notes: string,
  smart_thermostat_extra_notes: string,
  smart_thermostat_show: Bit,
  // #endregion smart_thermostat
  // #region solarTax
  solar_tax: YesNo,
  solar_tax_cost: string,
  solar_tax_customer_cost: string,
  solar_tax_notes: string,
  solar_tax_show: Bit,
  // #endregion solarTax
  solar_ppw_cap: string,

  type: string,
  // updateAt: number, TODO: to be used later

  // #region mainPanelUpgrade
  main_panel_upgrade: YesNo,
  main_panel_upgrade_cost: string,
  main_panel_upgrade_customer_cost: string,
  main_panel_upgrade_notes: string,
  main_panel_upgrade_extra_notes: string,
  main_panel_upgrade_show: Bit,
  main_panel_upgrade_needed_or_requested: string,
  // #endregion mainPanelUpgrade
  // #region panelRemoval
  panelRemoval: boolean,
  panel_removal: YesNo,
  panel_removal_how_many: string,
  panel_removal_cost: string,
  panel_removal_customer_cost: string,
  panel_removal_notes: string,
  panel_removal_extra_notes: string,
  panel_removal_show: Bit,
  // #endregion panelRemoval
  // #region checkList
  attach_thermal_camera: YesNo,
  attachThermalCamera: boolean,

  take_picture_of_home: YesNo,
  get_debts_report: YesNo,
  takePictureOfHome: boolean,
  perform_energy_assessment: YesNo,
  performEnergyAssessment: boolean,
  /**/ use_thermal_camera: YesNo,
  useThermalCamera: boolean,
  /**/ take_quiz: YesNo, // Now called survey
  takeQuiz: boolean,
  /**/ use_room_that_gets_hot: YesNo,
  useRoomThatGetsHot: boolean,
  /**/ use_smoke_pen: YesNo,
  useSmokePen: boolean,

  take_pictures: YesNo,
  takePictures: boolean,

  fill_out_prices: YesNo,
  fillOutPrices: boolean,

  verify_pricing_or_ppw: YesNo,
  verifyPricingOrPpw: boolean,

  show_ecohome_report: YesNo,
  showEcohomeReport: boolean,

  show_solo_report: YesNo,
  showSoloReport: boolean,
  site_survey_notes: string,

  /**/ /**/

  run_credit: YesNo,
  runCredit: boolean,

  change_to_correct_email: YesNo,
  changeToCorrectEmail: boolean,

  sign_financing_agreement: YesNo,
  signFinancingAgreement: boolean,
  /**/ sign_cosigner: YesNo,
  signCosigner: boolean,
  system_size: string,
  sub_panel_upgrade: YesNo,
  sub_panel_upgrade_cost: string,
  sub_panel_upgrade_customer_cost: string,
  sub_panel_upgrade_notes: string,
  sub_panel_upgrade_show: Bit,
  submit_install_docs_in_solo: YesNo,
  submitInstallDocsInSolo: boolean,
  /**/ counter_sign_rep_install_doc: YesNo,
  counterSignRepInstallDoc: boolean,
  /**/ sign_customer_install_docs: YesNo,
  signCustomerInstallDocs: boolean,

  ecohome_report: YesNo,
  ecohomeReport: boolean,
  /**/ make_sure_ecohome_and_solo_match: YesNo,
  makeSureEcohomeAndSoloMatch: boolean,
  /**/ take_picture_of_utility_bill: YesNo,
  /**/ takePictureOfUtilityBill: boolean,

  /**/ take_picture_of_id: YesNo,
  takePictureOfId: boolean,

  /**/ take_pictures_of_meter: YesNo,
  takePicturesOfMeter: boolean,
  /**/ submit_detailed_notes_for_efficiencies: YesNo,
  submitDetailedNotesForEfficiencies: boolean,
  /**/ review_notes_with_customers: YesNo,
  reviewNotesWithCustomers: boolean,
  /**/ sign_ecohome_report: YesNo,
  signEcohomeReport: boolean,
  /**/ get_google_review_and_or_pic_with_customer: YesNo,
  getGoogleReviewAndOrPicWithCustomer: boolean,
  /**/ take_pic_with_customer: YesNo,
  takePicWithCustomer: boolean,

  /**/
  offer_incentives_for_referrals: YesNo,
  offerIncentivesForReferrals: boolean,
  /**/

  update_notes_on_HL: YesNo,
  updateNotesOnHl: boolean,

  report_sale_to_slack: YesNo,
  reportSaleToSlack: boolean,

  // #endregion checkList

  //#region HIL
  hil_completion_client_sign: string,
  hil_completion_client_sign_date: number,
  hil_printed_name: string,
  //#endregion HIL

  // #region debts
  consolidationDebts: string,
  debtsPayments: string,
  collections: string,
  // #endregion debts

  // #region roofClaimingInputs
  //---- Main Page inputs ----
  roof_claiming_date: number,
  roof_claiming_homeowner: string,
  roof_claiming_phone: string,
  roof_claiming_phone_alt: string,
  roof_claiming_email: string,
  roof_claiming_address: string,
  roof_claiming_city: string,
  roof_claiming_zip: string,
  roof_claiming_sum: string,
  roof_claiming_deductible: string,
  roof_claiming_shingle_manufacturer: string,
  roof_claiming_style: string,
  roof_claiming_color: string,
  roof_claiming_drip_color: string,

  roof_claiming_age: string,
  roof_claiming_layers: string,
  roof_claiming_predominate_pitch: string,

  roof_claiming_pipe_jack: string,
  roof_claiming_chimney_flashing: string,
  roof_claiming_chimney_cap: string,
  roof_claiming_digital_satellite: string,
  roof_claiming_gas_cap: string,
  roof_claiming_other_one: string,

  roof_claiming_turtle_vent: string,
  roof_claiming_ridge_vent: string,
  roof_claiming_turbine_vent: string,
  roof_claiming_power_attic_vent: string,
  roof_claiming_other_two: string,
  roof_claiming_other_three: string,
  //---- End Main Page inputs ----
  //---- Main Page checkbox ----
  roof_claiming_tear_off_layers_shingles: Bit,
  roof_claiming_install_felt: Bit,
  roof_claiming_close_valleys: Bit,
  roof_claiming_ridges_color_coordinated: Bit,
  roof_claiming_install_new_flashings: Bit,
  roof_claiming_replace_ventilation: Bit,
  roof_claiming_install_nails: Bit,
  roof_claiming_clean_job_waste_gutters: Bit,
  roof_claiming_two_year_workmanship_warranty: Bit,
  roof_claiming_magnetic_sweep_property: Bit,

  roof_claiming_notes: string,
  roof_claiming_documents_notes: string,

  roof_claiming_date_loss: number,
  roof_claiming_insurance_company: string,
  roof_claiming_claim: string,

  roof_claiming_client_sign: string,
  roof_claiming_client_sign_date: number,
  roof_claiming_company_rep_sign: string,
  roof_claiming_company_rep_sign_date: number,

  roof_claiming_printed_name: string,
  roof_claiming_completion_client_sign: string,
  roof_claiming_completion_client_sign_date: number,

  roof_claiming_company_contractor: string,
  // #endregion roofClaiming

  // #region affordableRoofingContract
  ar_insurer: string,
  ar_policy_number: string,
  ar_services: string,
  ar_cause: string,
  ar_occurrence_timestamp: number,
  ar_occurrence_address: string,
  ar_pub_adjuster_fee: number,
  ar_pay_type: string,
  ar_pay_usd_amt: string,
  ar_customer_sig: string,
  ar_customer_sig_timestamp: number,
  ar_rep_sig_timestamp: number
  // #endregion affordableRoofingContract
} as const

const essentialFields: readonly CustomerField[] = [
  'has_touched_customer_cost',
  'has_touched_cost',
  'homeRep',
  'id',
  'deleted',
  'keyID',
  'customer_id_number',
  'efficiency_updated',
  'solarCompany',
  'solarEmail',
  'ssn',
  'solarRep',
  'yearHomeBuilt',
  'yearHomeYearBuilt',
  'yearly_energy_usage',
  'yearly_gas_usage',
  'globalNotes',
  'createdAt',
  'objectID',
  'company_name',
  'date',
  'key',
  'owner_house',
  'sort_key',
  'customerAddress',
  'customerEmail',
  'customerName',
  'customerPhone',
  'customerPhoneAlt',
  'customerReport',
  'customer_signature',
  'customer_initials'
]

export const customEfficiencyField = [
  'custom_fields',
  'custom_two_fields',
  'custom_three_fields',
  'custom_four_fields',
  'custom_five_fields',
  'custom_six_fields',
  'custom_seven_fields',
  'custom_eight_fields',
  'custom_nine_fields',
  'custom_ten_fields'
] as const
export type CustomEfficiencyField = typeof customEfficiencyField[number]
export const isCustomEfficiencyField = (
  o: unknown
): o is CustomEfficiencyField =>
  customEfficiencyField.includes(o as unknown as CustomEfficiencyField)

/**
 * Fields only used by the main company, not white labels.
 */
export const mainOnlyFields: readonly CustomerField[] = [
  'cash',
  'cash_cost',
  'cash_show',
  'solarEmail',
  'solar_tax',
  'solar_tax_cost',
  'solar_tax_customer_cost',
  'solar_tax_notes',
  'solar_tax_show',
  'glow',
  'glow_cost',
  'glow_customer_cost',
  'glow_price_per_watt',
  'glow_power_output_mw',
  'glow_notes',
  'glow_extra_notes',
  'glow_show',
  //#region roofClaimings
  'roof_claiming_date',
  'roof_claiming_homeowner',
  'roof_claiming_phone',
  'roof_claiming_phone_alt',
  'roof_claiming_email',
  'roof_claiming_address',
  'roof_claiming_city',
  'roof_claiming_zip',
  'roof_claiming_sum',
  'roof_claiming_deductible',
  'roof_claiming_shingle_manufacturer',
  'roof_claiming_style',
  'roof_claiming_color',
  'roof_claiming_drip_color',
  'roof_claiming_age',
  'roof_claiming_layers',
  'roof_claiming_predominate_pitch',
  'roof_claiming_pipe_jack',
  'roof_claiming_chimney_flashing',
  'roof_claiming_chimney_cap',
  'roof_claiming_digital_satellite',
  'roof_claiming_gas_cap',
  'roof_claiming_other_one',
  'roof_claiming_turtle_vent',
  'roof_claiming_ridge_vent',
  'roof_claiming_turbine_vent',
  'roof_claiming_power_attic_vent',
  'roof_claiming_other_two',
  'roof_claiming_other_three',
  'roof_claiming_tear_off_layers_shingles',
  'roof_claiming_install_felt',
  'roof_claiming_close_valleys',
  'roof_claiming_ridges_color_coordinated',
  'roof_claiming_install_new_flashings',
  'roof_claiming_replace_ventilation',
  'roof_claiming_install_nails',
  'roof_claiming_clean_job_waste_gutters',
  'roof_claiming_two_year_workmanship_warranty',
  'roof_claiming_magnetic_sweep_property',
  'roof_claiming_notes',
  'roof_claiming_documents_notes',
  'roof_claiming_date_loss',
  'roof_claiming_insurance_company',
  'roof_claiming_claim',
  'roof_claiming_client_sign',
  'roof_claiming_client_sign_date',
  'roof_claiming_company_rep_sign',
  'roof_claiming_company_rep_sign_date',
  'roof_claiming_printed_name',
  'roof_claiming_completion_client_sign',
  'roof_claiming_completion_client_sign_date',
  'roof_claiming_company_contractor'
  //#endregion roofClaimings
]

export const glowFields: readonly CustomerField[] = [
  ...essentialFields,
  'air_conditioner',
  'air_conditioner_any_swamp_cooler',
  'air_conditioner_cost',
  'air_conditioner_current_tons',
  'air_conditioner_customer_cost',
  'air_conditioner_extra_notes',
  'air_conditioner_new_tons',
  'air_conditioner_notes',
  'air_conditioner_replace_curve',
  'air_conditioner_replace_ducts',
  'air_conditioner_show',
  'air_conditioner_size',
  'air_conditioner_swamp_cooler_use',
  'air_conditioner_swamp_cooler_use_remove',
  'air_conditioner_tons_how_many',
  'air_conditioner_unit_type',
  'air_conditioner_working',
  'airConditioner',

  'attic_insulation',
  'attic_insulation_cost',
  'attic_insulation_customer_cost',
  'attic_insulation_extra_notes',
  'attic_insulation_how_much',
  'attic_insulation_levels_type',
  'attic_insulation_notes',
  'attic_insulation_show',
  'attic_insulation_type',
  'attic_square_footage',
  'atticInsulation',

  'battery',
  'battery_cost',
  'battery_customer_cost',
  'battery_notes',
  'battery_show',
  'battery_size',
  'battery_type',

  'main_panel_upgrade',
  'main_panel_upgrade_cost',
  'main_panel_upgrade_customer_cost',
  'main_panel_upgrade_needed_or_requested',
  'main_panel_upgrade_installation_company',
  'main_panel_upgrade_notes',
  'main_panel_upgrade_show',

  'glow',
  'glow_cost',
  'glow_customer_cost',
  'glow_notes',
  'glow_extra_notes',
  'glow_power_output_mw',
  'glow_price_per_watt',
  'glow_show',

  'roof',
  'roofCheck',
  'roof_california_city',
  'roof_cost',
  'roof_fascia_included',
  'roof_fascia_included_square_footage',
  'roof_plywood_replaced',
  'roof_plywood_replaced_square',
  'roof_plywood_custom_roughly',
  'roof_layover_or_tear',
  'roof_patio_included',
  'roof_patio_how_many',
  'roof_customer_cost',
  'roof_layers_how_many',
  'roof_square_footage',
  'roof_extra_notes',
  'roof_notes',
  'roof_show',

  'custom_fields',
  'custom_fields_cost',
  'custom_fields_customer_cost',
  'custom_fields_description',
  'custom_fields_name',
  'custom_fields_notes',
  'custom_fields_show',
  'custom_two_fields',
  'custom_two_fields_cost',
  'custom_two_fields_customer_cost',
  'custom_two_fields_description',
  'custom_two_fields_name',
  'custom_two_fields_notes',
  'custom_two_fields_show',
  'custom_three_fields',
  'custom_three_fields_cost',
  'custom_three_fields_customer_cost',
  'custom_three_fields_description',
  'custom_three_fields_name',
  'custom_three_fields_notes',
  'custom_three_fields_show',
  'custom_four_fields',
  'custom_four_fields_cost',
  'custom_four_fields_customer_cost',
  'custom_four_fields_description',
  'custom_four_fields_name',
  'custom_four_fields_notes',
  'custom_four_fields_show',
  'custom_five_fields',
  'custom_five_fields_cost',
  'custom_five_fields_customer_cost',
  'custom_five_fields_description',
  'custom_five_fields_name',
  'custom_five_fields_notes',
  'custom_five_fields_show',
  'custom_six_fields',
  'custom_six_fields_cost',
  'custom_six_fields_customer_cost',
  'custom_six_fields_description',
  'custom_six_fields_name',
  'custom_six_fields_notes',
  'custom_six_fields_show',
  'custom_seven_fields',
  'custom_seven_fields_cost',
  'custom_seven_fields_customer_cost',
  'custom_seven_fields_description',
  'custom_seven_fields_name',
  'custom_seven_fields_notes',
  'custom_seven_fields_show',
  'custom_eight_fields',
  'custom_eight_fields_cost',
  'custom_eight_fields_customer_cost',
  'custom_eight_fields_description',
  'custom_eight_fields_name',
  'custom_eight_fields_notes',
  'custom_eight_fields_show',
  'custom_nine_fields',
  'custom_nine_fields_cost',
  'custom_nine_fields_customer_cost',
  'custom_nine_fields_description',
  'custom_nine_fields_name',
  'custom_nine_fields_notes',
  'custom_nine_fields_show',
  'custom_ten_fields',
  'custom_ten_fields_cost',
  'custom_ten_fields_customer_cost',
  'custom_ten_fields_description',
  'custom_ten_fields_name',
  'custom_ten_fields_notes',
  'custom_ten_fields_show'
]

export const customFields = [
  'custom_fields',
  'custom_two_fields',
  'custom_three_fields',
  'custom_four_fields',
  'custom_five_fields',
  'custom_six_fields',
  'custom_seven_fields',
  'custom_eight_fields',
  'custom_nine_fields',
  'custom_ten_fields'
] as const

// TODO: Do this via database
if (env.IS_WHITE_LABEL) {
  if (env.CODENAME === 'glow') {
    const nonGlowFields = u
      .keys(CustomerSchema)
      .filter((field) => !glowFields.includes(field))
    for (const field of nonGlowFields) {
      delete CustomerSchema[field]
    }
  } else {
    for (const field of mainOnlyFields) {
      delete CustomerSchema[field]
    }
  }
}

/**
 * Defines in compile-time the Customer type for use in compilation.
 */
export type Customer = typeof CustomerSchema

export type CustomerField = keyof Customer

export type CustomerFields = readonly CustomerField[]

export const customerFields: CustomerFields = u.keys(CustomerSchema)

export type CustomerValue = u.ValueOf<Customer>

export const multipleChoiceFields = [
  'air_conditioner_current_tons',
  'air_conditioner_new_tons',
  'air_conditioner_unit_type',
  'attic_insulation_type',
  'battery_size',
  'battery_type',
  'homeRep',
  'main_panel_upgrade_needed_or_requested',
  'main_panel_upgrade_installation_company',
  'new_windows_california_city',
  'new_windows_color',
  'products',
  'roof_layover_or_tear',
  'roof_layers_how_many',
  'roof_tear_material',
  'solarCompany',
  'solarRep'
] as const

export type MultipleChoiceField = typeof multipleChoiceFields[number]

export type TouchedCost = {
  [x in CustomerField]?: boolean
}

const customerFieldDropDown = [
  'air_conditioner_current_tons',
  'air_conditioner_tons_how_many',
  'air_conditioner_new_tons',
  'air_conditioner_unit_type',
  'battery_type',
  'battery_size',
  'homeRep',
  'main_panel_upgrade_needed_or_requested',
  'main_panel_upgrade_installation_company',
  'new_windows_california_city',
  'new_windows_color',
  'new_windows_sliding_glass_size',
  'roof_california_city',
  'roof_layers_how_many',
  'roof_layover_or_tear',
  'solarCompany',
  'solarRep'
] as const

export type CustomerFieldDropDown = typeof customerFieldDropDown[number]

export const isDropdownField = (o: unknown): o is CustomerFieldDropDown => {
  if (typeof o !== 'string') return false
  return customerFieldDropDown.includes(o as CustomerFieldDropDown)
}

interface ValidateCustomerRes {
  /**
   * Will be true if no error was found.
   */
  ok: boolean
  /**
   * Will be a populated string if any general error occurs while validating.
   */
  err: string
  /**
   * Will be populated if any error with an specific field was found.
   */
  errMap: u.Writable<{
    [K in keyof Customer]?: string
  }>
  /**
   * If ok is `true`, contains the customer in a correct data format.
   */
  sanitized: Customer
}

const sanitizeCustomer = (customer: Record<string, unknown>): Customer => {
  const result = {} as u.Writable<Customer>

  for (const [k, validator] of u.entries(CustomerSchema)) {
    // CAST: not sure what's going on here
    result[k] = customer[k] as never

    if (validator === string) {
      // CAST: not sure what's going on here
      result[k] = sanitizeString(customer[k]) as never
    }
    if (validator === StrToPrimitive) {
      result[k] = sanitizeStrToPrimitive(customer[k]) as never
    }
    if (validator === boolean) {
      // CAST: not sure what's going on here
      result[k] = sanitizeBoolean(customer[k]) as never
    }
    if (validator === YesNo) {
      // CAST: not sure what's going on here
      result[k] = sanitizeYesNo(customer[k]) as never
    }
    if (validator === YesNoEmpty) {
      // CAST: not sure what's going on here
      result[k] = sanitizeYesNoEmpty(customer[k]) as never
    }
    if (validator === Bit) {
      // CAST: not sure what's going on here
      result[k] = sanitizeBit(customer[k]) as never
    }
    if (validator === number) {
      // CAST: not sure what's going on here
      result[k] = sanitizeNumber(customer[k]) as never
    }

    if (k === 'customerPhone' || k === 'customerPhoneAlt') {
      result[k] = u.normalizePhoneNumber(result[k])
    }
    if (k === 'new_windows_color') {
      result[k] = 'White'
    }

    if (k === 'new_windows')
      result.new_windows_sqft_each_window = JSON.stringify([newWindowGroup()])

    if (k === 'mini_split') {
      result.mini_split_tons = JSON.stringify([newMiniSplit()])
    }

    if (k === 'release_liability_date') {
      // alert('release_liability_date');
      // alert(customer.release_liability_date);
      // @ts-ignore
      result.release_liability_date =
        customer['release_liability_date'] ||
        u.normalizeTimestampToMs(Date.now())
      // alert('result.release_liability_date');
    }

    if (k === 'roof_tear_material') {
      // @ts-ignore
      result.roof_tear_material = customer['roof_tear_material'] || '30'
    }
  }

  return result
}

export const validateCustomer = (
  o: Record<string, unknown>
): ValidateCustomerRes => {
  const result: ValidateCustomerRes = {
    ok: true,
    err: '',
    errMap: {},
    sanitized: {} as Customer
  }

  try {
    if (typeof o !== 'object' || o === null) {
      throw new TypeError('Not an object')
    }

    result.sanitized = sanitizeCustomer({
      ...createEmptyCustomer({
        customerName: o['customerName'] as string,
        firebaseKey: (o['key'] ||
          o['objectID'] ||
          o['id'] ||
          o['keyID']) as string,
        customerAddress: '',
        customerPhone: '',
        customerPhoneAlt: '',
        ecohomeRep: '',
        // CAST: There should be one company at least
        solarCompany: env.COMPANY_VALUES[0]!,
        solarRep: ''
      }),
      ...o
    })

    if (result.sanitized.key === '') {
      result.errMap.key = 'Expected the key, got empty string'
      throw new ReferenceError(`Fatal: No customer key detected.`)
    }
    if (typeof result.sanitized.key === 'undefined') {
      result.errMap.key = 'Expected the key, got undefined'
      throw new ReferenceError(`Fatal: No customer key detected.`)
    }
    if (result.sanitized.key === null) {
      result.errMap.key = 'Expected the key, got null'
      throw new ReferenceError(`Fatal: No customer key detected.`)
    }
    // @ts-expect-error
    if (result.sanitized.key === true) {
      result.errMap.key = 'Expected the key, got true'
      throw new ReferenceError(`Fatal: No customer key detected.`)
    }
    // @ts-expect-error
    if (result.sanitized.key === false) {
      result.errMap.key = 'Expected the key, got false'
      throw new ReferenceError(`Fatal: No customer key detected.`)
    }
    if (typeof result.sanitized.key === 'object') {
      result.errMap.key = 'Expected the key, got object'
      throw new ReferenceError(`Fatal: No customer key detected.`)
    }
    if (result.sanitized.key === '[object Object]') {
      result.errMap.key = 'Expected the key, got [object Object]'
      throw new ReferenceError(`Fatal: No customer key detected.`)
    }

    // This step is a bit extraneous since the customer was already sanitized at
    // this point, but this helps keep validators and sanitizers in sync, thus
    // ensuring validators work as intended at write time as well.
    for (const [key, _validator] of Object.entries(CustomerSchema)) {
      const validator = _validator as unknown as (value: unknown) => boolean
      const value = (result.sanitized as Record<string, unknown>)[key]
      const isValid = validator(value)

      if (!isValid) {
        result.errMap[
          key as keyof Customer
        ] = `Expected ${validator.name}, got: ${value}`
      }
    }
  } catch (e) {
    result.err = u.processErr(e)
  } finally {
    if (result.err || Object.keys(result.errMap).length > 0) {
      result.ok = false

      u.log(
        `validateCustomer() -> Validation failed for customer ${o['key']} -> `,
        { ...result, sanitized: undefined }
      )
    }

    // eslint-disable-next-line no-unsafe-finally
    return result
  }
}

export const createEmptyCustomer = ({
  firebaseKey,
  ecohomeRep,
  crm_id,
  customerName,
  customerAddress,
  customerPhone,
  customerPhoneAlt,
  main_panel_upgrade_installation_company,
  products,
  solarCompany,
  solarRep
}: {
  firebaseKey: string
  ecohomeRep?: string
  crm_id?: string
  customerName?: string
  customerAddress?: string
  customerPhone?: string
  customerPhoneAlt?: string
  main_panel_upgrade_installation_company?: string
  products?: string
  solarCompany?: string
  solarRep?: string
}): Customer => {
  const result = {} as u.Writable<Customer>

  for (const [key, validator] of Object.entries(CustomerSchema)) {
    const k = key as keyof Customer

    if (validator === number) {
      // CAST: not sure what's going on here
      result[k] = 0 as never
    }
    if (validator === string) {
      // CAST: not sure what's going on here
      result[k] = '' as never
    }
    if (validator === boolean) {
      // CAST: not sure what's going on here
      result[k] = false as never
    }
    if (validator === YesNo) {
      result[k] = 'no' as never
    }
    if (validator === YesNoEmpty) {
      result[k] = '' as never
    }
    if (validator === Bit) {
      // CAST: not sure what's going on here
      result[k] = 1 as never
    }
    if (validator === StrToPrimitive) {
      // CAST: not sure what's going on here
      result[k] = {} as never
    }
  }

  result.homeRep = ecohomeRep || 'n/a'
  result.crm_id = crm_id || ''
  result.customerName = customerName || ''
  result.customerAddress = customerAddress || ''
  result.customerPhone = customerPhone || ''
  result.customerPhoneAlt = customerPhoneAlt || ''
  result.main_panel_upgrade_installation_company =
    main_panel_upgrade_installation_company || 'To Be Decided'
  result.solarCompany = solarCompany || ''
  result.solarRep = solarRep || 'n/a'
  result.key = firebaseKey
  result.id = firebaseKey
  result.keyID = firebaseKey
  result.createdAt = u.normalizeTimestampToMs(Date.now())
  result.date = u.normalizeTimestampToMs(Date.now())
  result.sort_key = u.normalizeTimestampToMs(Date.now()) * -1
  result.owner_house = ''
  result.auditorReport = firebaseKey + '_auditor.pdf'
  result.customerReport = firebaseKey + '_customer.pdf'
  result.invoiceReport = firebaseKey + '_invoice.pdf'

  result.roof_claiming_homeowner = customerName || ''
  result.roof_claiming_phone = customerPhone || ''

  result.roof_claiming_printed_name = customerName || ''

  result.new_windows_color = 'White'

  result.release_liability_date = u.normalizeTimestampToMs(Date.now())

  result.products = products || ''

  return result
}

export interface SimpleCustomer {
  customerAddress: string
  customerName: string
  /**
   * Unique identifier.
   */
  key: string
  sort_key: number
  /**
   * Alias for key.
   */
  objectID: string
  solarCompany: string
  solarRep: string
  deleted?: boolean
  homeRep?: string
  date?: number

  /**
   * @danlugo92: Avoids typescript accepting a Customer in place for a SimpleCustomer which
   * could be a mistake of our part.
   */
  _zSimple: null
}

export type GlowType = 'glow_silver' | 'glow_gold'

export const glowTypeToLabel: u.DeepReadonly<Record<GlowType, string>> = {
  glow_gold: 'Glow Gold',
  glow_silver: 'Glow Silver'
}

export type GlowStatus = 'activated' | 'pending' | 'submitted'

export interface Glower extends SimpleCustomer {
  readonly glowStatus: GlowStatus
}

export const glowStatusToLabel: Readonly<Record<GlowStatus, string>> = {
  activated: 'Activated',
  pending: 'Pending',
  submitted: 'Submitted'
}

export const simplifyCustomer = (customer: Customer): SimpleCustomer => {
  // if (typeof customer.key !== 'string') {
  //   throw new Error('No key in customer supplied to simplifyCustomer()')
  // }
  return {
    customerAddress: customer.customerAddress,
    customerName: customer.customerName,
    key: customer.key,
    objectID: customer.key || customer.objectID,
    solarCompany: customer.solarCompany,
    solarRep: customer.solarRep,
    sort_key: customer.sort_key,
    deleted: customer.deleted,
    homeRep: customer.homeRep,
    date: customer.date,
    _zSimple: null
  }
}

export type MediaKind =
  | 'adders'
  | 'hvac_system'
  | 'electrical'
  | 'electricity'
  | 'id'
  | 'insulation'
  | 'owner_house'
  | 'problem'
  | 'roof'
  | 'temperature'
  | 'thermal'
  | 'other_documents'
  | 'solar_proposal'
  | 'all_exterior_walls_site_survey'
  | 'rafters_site_survey'
  | 'electrical_panel_site_survey'
  | 'all_planes_roof_site_survey'
  | 'other_site_survey'
  | 'house360_site_survey'
  | 'attic_site_survey'
  | 'roof_site_survey'
  | 'windows'
  | 'rc_front_house'
  | 'rc_address_verification'
  | 'rc_3d_view'
  | 'rc_wires_home'
  | 'rc_siding'
  | 'rc_other_property_wind_damage'
  | 'rc_wind_damages'
  | 'rc_layers'
  | 'rc_roof_inclination'
  | 'rc_roof_overview'
  | 'rc_gutters'
  | 'rc_flashings'
  | 'rc_penetrations'
  | 'rc_hail'
  | 'rc_length_shingle'
  | 'rc_drip_edge'
  | 'rc_shingles_color'
  | 'rc_garage'
  | 'rc_ice_shield'

export const MediaKinds: Record<MediaKind, MediaKind> = {
  adders: 'adders',
  hvac_system: 'hvac_system',
  all_exterior_walls_site_survey: 'all_exterior_walls_site_survey',
  all_planes_roof_site_survey: 'all_planes_roof_site_survey',
  attic_site_survey: 'attic_site_survey',
  electrical_panel_site_survey: 'electrical_panel_site_survey',
  electrical: 'electrical',
  electricity: 'electricity',
  house360_site_survey: 'house360_site_survey',
  id: 'id',
  insulation: 'insulation',
  other_documents: 'other_documents',
  other_site_survey: 'other_site_survey',
  owner_house: 'owner_house',
  problem: 'problem',
  rafters_site_survey: 'rafters_site_survey',
  roof_site_survey: 'roof_site_survey',
  roof: 'roof',
  solar_proposal: 'solar_proposal',
  temperature: 'temperature',
  thermal: 'thermal',
  windows: 'windows',
  rc_front_house: 'rc_front_house',
  rc_address_verification: 'rc_address_verification',
  rc_3d_view: 'rc_3d_view',
  rc_wires_home: 'rc_wires_home',
  rc_siding: 'rc_siding',
  rc_other_property_wind_damage: 'rc_other_property_wind_damage',
  rc_wind_damages: 'rc_wind_damages',
  rc_layers: 'rc_layers',
  rc_roof_inclination: 'rc_roof_inclination',
  rc_roof_overview: 'rc_roof_overview',
  rc_gutters: 'rc_gutters',
  rc_flashings: 'rc_flashings',
  rc_penetrations: 'rc_penetrations',
  rc_hail: 'rc_hail',
  rc_length_shingle: 'rc_length_shingle',
  rc_drip_edge: 'rc_drip_edge',
  rc_shingles_color: 'rc_shingles_color',
  rc_garage: 'rc_garage',
  rc_ice_shield: 'rc_ice_shield'
}

export const mainOnlyMedia: readonly MediaKind[] = ['adders']

export const glowMedia: readonly MediaKind[] = [
  'roof',
  'roof_site_survey',
  'electrical',
  'electrical_panel_site_survey',
  'roof',
  'roof_site_survey',
  'insulation',
  'id',
  'hvac_system',
  'owner_house',
  'other_documents'
]

if (env.IS_WHITE_LABEL) {
  if (env.CODENAME === 'glow') {
    const allKinds = u.keys(MediaKinds)
    const nonGlowKinds = allKinds.filter((field) => !glowMedia.includes(field))
    for (const field of nonGlowKinds) {
      delete MediaKinds[field]
    }
  } else {
    for (const mediaKind of mainOnlyMedia) {
      delete MediaKinds[mediaKind]
    }
  }
}

export type MediaItemStatus = 'draft' | 'pending' | 'uploading' | 'uploaded'

export const isMediaItemStatus = (s: unknown): s is MediaItemStatus => {
  if (typeof s !== 'string') return false
  return ['pending', 'uploading', 'uploaded'].includes(s)
}

export const normalizeCheckShows: Partial<
  Record<keyof Customer, keyof Customer>
> = {
  attic_insulation: 'attic_insulation_show',
  battery: 'battery_show',
  duct_seal: 'duct_seal_show',
  roof: 'roof_show',
  new_windows: 'new_windows_show',
  air_conditioner: 'air_conditioner_show',
  main_panel_upgrade: 'main_panel_upgrade_show',
  mini_split: 'mini_split_show',
  derate: 'derate_show',
  smart_thermostat: 'smart_thermostat_show',
  attic_fan: 'attic_fan_show',
  pool_pump: 'pool_pump_show',

  duct_repair: 'duct_repair_show',
  duct_insulation: 'duct_insulation_show',

  cash: 'cash_show',
  solar_tax: 'solar_tax_show',
  small_system: 'small_system_show',
  flat_roof_panels: 'flat_roof_panels_show',
  panel_removal: 'panel_removal_show',
  custom_fields: 'custom_fields_show',
  custom_two_fields: 'custom_two_fields_show',
  custom_three_fields: 'custom_three_fields_show',
  custom_four_fields: 'custom_four_fields_show',
  custom_five_fields: 'custom_five_fields_show',
  custom_six_fields: 'custom_six_fields_show',
  custom_seven_fields: 'custom_seven_fields_show',
  custom_eight_fields: 'custom_eight_fields_show',
  custom_nine_fields: 'custom_nine_fields_show',
  custom_ten_fields: 'custom_ten_fields_show'
}

export interface MediaItem {
  /**
   * User-inputted description.
   */
  description: string
  /**
   * File height.
   */
  height?: number
  /**
   * Empty string if there's no errors. This should also hold any error that
   * happened when trying to upload.
   */
  err: string
  /**
   * Empty if it's a video.
   */
  image_of: string
  /**
   * File name.
   */
  name: string
  /**
   * The customer's id.
   */
  order_id: string
  /**
   * Local path inside device. Used when uploading or retrying uploading.
   * Useless afterwards.
   */
  path: string
  /**
   * Might not be present in older media in database, sanitize before using.
   */
  status: MediaItemStatus
  /**
   * Reduced quality base64.
   */
  thumbnail: string
  /**
   * Unix timestamp.
   */
  updated_at: number
  /**
   * URL constructed by backend API, will not be present if API is not being
   * used.
   */
  url: string
  /**
   * Empty if it's a photo.
   */
  video_of: string
  /**
   * File width.
   */
  width?: number
}

export const processMediaItem = (maybeMediaItem: unknown): MediaItem => {
  const mi = maybeMediaItem as MediaItem

  const res: MediaItem = {
    description: '',
    err: '',
    image_of: '',
    name: '',
    order_id: '',
    path: '',
    status: 'uploaded',
    thumbnail: '',
    updated_at: 0,
    url: '',
    video_of: ''
  }

  try {
    if (maybeMediaItem === null || typeof maybeMediaItem !== 'object') {
      throw new TypeError('Not an object')
    }

    // Mandatory stuff

    if (typeof mi.image_of === 'string' && mi.image_of.length > 3) {
      res.image_of = mi.image_of
    } else if (typeof mi.video_of === 'string' && mi.video_of.length > 3) {
      res.video_of = mi.video_of
    } else {
      throw new TypeError(
        'image_of / video_of not an string or too short of a string'
      )
    }
    if (typeof mi.name === 'string' && mi.name.length > 5) {
      res.name = mi.name
    } else {
      throw new TypeError('name not a string or too short of a string')
    }
    if (typeof mi.order_id === 'string' && mi.order_id.length > 5) {
      res.order_id = mi.order_id
    } else {
      throw new TypeError('order_id not a string or too short')
    }

    // Optional stuff
    res.description = mi.description || ''
    res.err = typeof mi.err === 'string' ? mi.err : ''
    res.status = isMediaItemStatus(mi.status) ? mi.status : 'uploaded'
    res.updated_at =
      typeof mi.updated_at === 'number' ? mi.updated_at : Date.now()
    res.url = mi.url || ''
    res.thumbnail = mi.thumbnail || ''
    // Already uploaded stuff doesn't need the path anyways
    res.path = (typeof mi.path === 'string' && mi.path) || ''
  } catch (e: unknown) {
    u.log(e)
    u.log(maybeMediaItem)
    res.err = u.processErr(e)
  }

  return res
}

export const extractMediaKind = (mediaItem: MediaItem): MediaKind => {
  const { image_of, video_of } = mediaItem

  const mediaKindOfImage = image_of.replace('_pictures', '') as MediaKind
  const mediaKindOfVideo = video_of.replace('_videos', '') as MediaKind

  if (MediaKinds[mediaKindOfImage] || MediaKinds[mediaKindOfVideo]) {
    return mediaKindOfImage || mediaKindOfVideo
  }

  throw new TypeError(
    `Could not extract media kind from item: ${JSON.stringify(
      mediaItem,
      null,
      2
    )}`
  )
}

export const cashbackLabel: Partial<Record<keyof Customer, string>> = {
  cash_pool_pumps: 'Pool Pumps',
  cash_heat_pumps: 'Heat Pumps',
  cash_whole_house_fans_or_ventilation: 'Whole House Fans / Ventilation',
  cash_efficient_boilers: 'Efficient Boilers',
  cash_geothermal_heat_pumps: 'Geothermal Heat Pumps',
  cash_radiant_floors: 'Radiant Floors',
  cash_water_saving_fixtures: 'Water Saving Fixtures',
  cash_solar_water_heaters: 'Solar Water Heaters',
  cash_on_demand_or_tankless_hot: 'On Demand / Tankless Hot Water Heater',
  cash_efficient_water_heaters: 'Efficient Water Heaters',
  cash_artificial_turf: 'Artificial Turf',
  cash_irrigation_systems: 'Irrigation Systems',
  cash_windows: 'Windows',
  cash_floor_insulation: 'Floor Insulation',
  cash_air_conditioner: 'Air Conditioner'
}

export type Selector = {
  label: string
  onChange: () => void
  opts: u.Opts
  value: string
}

export const cashBackOptions = (): u.Opts =>
  u.keys(cashbackLabel).map((key) => ({
    value: key,
    label: cashbackLabel[key as keyof Customer] as string
  }))

export const homeRepOptions: u.Opts = [
  { value: 'Abel Zavalza', label: 'Abel Zavalza' },
  { value: 'Alan Duplan', label: 'Alan Duplan' },
  { value: 'Angel Ferniza', label: 'Angel Ferniza' },
  { value: 'Cesar Barron', label: 'Cesar Barron' },
  { value: 'Francisco Gonzalez', label: 'Francisco Gonzalez' },
  { value: 'Gui Meira', label: 'Gui Meira' },
  { value: 'Helder Mejia', label: 'Helder Mejia' },
  { value: 'Jaron Gallagher', label: 'Jaron Gallagher' },
  { value: 'Jhon Guerrero', label: 'Jhon Guerrero' },
  { value: 'Luis Solorio', label: 'Luis Solorio' },
  { value: 'Mario Gonzalez', label: 'Mario Gonzalez' },
  { value: 'Jose Villafran', label: 'Jose Villafran' },
  { value: 'Edson Miranda', label: 'Edson Miranda' },
  { value: 'Saul Salcedo', label: 'Saul Salcedo' },
  { value: 'Sergio Farfan', label: 'Sergio Farfan' }
]

export const solarRepOptions: u.Opts = [
  { value: 'Allan Rodriguez', label: 'Allan Rodriguez' },
  { value: 'Anais Diaz', label: 'Anais Diaz' },
  { value: 'Francisco Novsak', label: 'Francisco Novsak' },
  { value: 'Michael Gray', label: 'Michael Gray' },
  { value: 'Patricia Compadre', label: 'Patricia Compadre' },
  { value: 'Ricardo Castellano', label: 'Ricardo Castellano' },
  { value: 'Thaide Toro', label: 'Thaide Toro' },
  { value: 'Referral', label: 'Referral' }
]

/**
 * There are no more separate HIL/RC "companies", easiest way to unify was just
 * give them all the same label (e.g. "California") and treat them as "state".
 */
const _companyOptions: u.Opts = u.zipWith(
  env.COMPANY_LABELS,
  env.COMPANY_VALUES,
  (label, value) => ({
    label,
    value
  })
)

/**
 * As of the time of this comment, the "normal" companies (non HIL/RC) are
 * specificized first in the env, and uniqBy() will conserve those and discard
 * the HIL/RC ones which is the desired behavior.
 */
export const companyOptions: u.Opts = uniqBy(_companyOptions, 'label')

export const convertToOptions = (
  optionsStr: unknown[] | unknown | u.Primitive | undefined
): u.Opts => {
  const options = JSON.parse(
    typeof optionsStr === 'string' ? optionsStr : '[]'
  ) as string[]

  return u.zipWith(options, options, (label, value) => ({
    label,
    value
  }))
}

export const acTons: u.Opts = [
  { value: '5', label: '5T' },
  { value: '4', label: '4T' },
  { value: '3.5', label: '3.5T' },
  { value: '3', label: '3T' },
  { value: '2.5', label: '2.5T' },
  { value: '2', label: '2T' }
]

export const acSplitUnit: u.Opts = [
  { value: 'roof_gas', label: 'Roof' },
  { value: 'split_gas', label: 'Split Gas' },
  { value: 'split_electric', label: 'Split Electric' }
]

export const airConditionerTons: u.Opts = [
  { value: '5T Split Unit Electric', label: '5T Split Unit Electric' },
  { value: '5T Split Unit Gas', label: '5T Split Unit Gas' },
  { value: '5T Roof', label: '5T Roof' },
  { value: '4T Split Unit Electric', label: '4T Split Unit Electric' },
  { value: '4T Split Unit Gas', label: '4T Split Unit Gas' },
  { value: '4T Roof', label: '4T Roof' },
  { value: '3.5T Split Unit Electric', label: '3.5T Split Unit Electric' },
  { value: '3.5T Split Unit Gas', label: '3.5T Split Unit Gas' },
  { value: '3.5T Roof', label: '3.5T Roof' },
  { value: '3T Split Unit Electric', label: '3T Split Unit Electric' },
  { value: '3T Split Unit Gas', label: '3T Split Unit Gas' },
  { value: '3T Roof', label: '3T Roof' },
  { value: '2.5T Split Unit Electric', label: '2.5T Split Unit Electric' },
  { value: '2.5T Split Unit Gas', label: '2.5T Split Unit Gas' },
  { value: '2.5T Roof', label: '2.5T Roof' },
  { value: '2T Split Unit Electric', label: '2T Split Unit Electric' },
  { value: '2T Split Unit Gas', label: '2T Split Unit Gas' },
  { value: '2T Roof', label: '2T Roof' },
  { value: 'Mini Split System 1Ton', label: 'Mini Split System 1Ton' },
  { value: 'Mini Split System 2Ton', label: 'Mini Split System 2Ton' }
]

export const areasDuctsOptions: u.Opts = [
  { value: 'Whole house', label: 'Whole house' },
  { value: 'Custom', label: 'Custom' }
]

// TODO: Use this enum everywhere
export enum RoofWorkType {
  Layover = 'Layover',
  TearOff = 'Tear off',
  TileRepl = 'Tile Replacement',
  UnderlaymentRepl = 'Underlayment Replacement'
}

export const roofWorkTypes: u.Opts = [
  { value: RoofWorkType.TearOff, label: 'Tear off' },
  { value: RoofWorkType.Layover, label: 'Layover' },
  { value: RoofWorkType.TileRepl, label: 'Tile Replacement' },
  { value: RoofWorkType.UnderlaymentRepl, label: 'Underlayment Replacement' }
]

export const californiaCity: u.Opts = [
  { value: 'fresno', label: 'Fresno' },
  { value: 'victorville', label: 'Southern California' }
]

export const atticInsulationOptions: u.Opts = [
  { value: 'Fiberglass', label: 'Fiberglass' },
  { value: 'Rockwool', label: 'Rockwool' }
]

export const mainPanelUpgradeNeededOrRequested: u.Opts = [
  { value: 'needed', label: ' If Needed' },
  { value: 'requested', label: 'Requested' },
  { value: 'relocation', label: 'Relocation' }
]

export const typeAcInstallation: u.Opts = [
  { value: 'new installation', label: 'New Installation' },
  { value: 'replacement', label: 'Replacement' }
]

export type SlidingDoorsSizeKey =
  | 'Sliding Doors (5 ft)'
  | 'Sliding Doors (6 ft)'
  | 'Sliding Doors (8 ft)'

export const slidingToSize = {
  'Sliding Doors (5 ft)': 5,
  'Sliding Doors (6 ft)': 6,
  'Sliding Doors (8 ft)': 8
}

export const slidingDoorsSize: u.Opts = [
  { value: 'Sliding Doors (5 ft)', label: '5 ft' },
  { value: 'Sliding Doors (6 ft)', label: '6 ft' },
  { value: 'Sliding Doors (8 ft)', label: '8 ft' }
]

export const miniSplitSystems: u.Opts = [
  { value: 'mini_split_1t', label: 'Mini Split System 1T' },
  { value: 'mini_split_2t', label: 'Mini Split System 2T' }
]

export const tearOffLayerOpts: u.Opts = [
  { value: '1', label: '1' },
  { value: '2', label: '2' },
  { value: '3', label: '3' },
  { value: '4', label: '4' }
]

export const tearOffMaterialOpts: u.Opts = [
  {
    label: '20 Years',
    value: '20'
  },
  {
    label: '30 Years',
    value: '30'
  },
  {
    label: '50 Years',
    value: '50'
  }
]

export const layoverLayersOpts: u.Opts = [
  { value: '1', label: '1' },
  { value: '2', label: '2' },
  { value: '3', label: '3' }
]

export interface DebtPayment {
  consolidation_debt_id: string
  debt: string
  id: string
  payment: string
}

export type DebtPayments = DebtPayment[]

export interface Collection {
  consolidation_debt_id: string
  id: string
  value: string
}

export type Collections = Collection[]
export interface ConsolidationDebtData {
  address: string
  closer: string
  creditScore: string
  id: string
  name: string
  notes: string
}

export type roofPlywoodOption = 'As needed' | 'Whole Roof'

// #region Rowable

export type Rowable = MiniSplit | SlidingGlassDoorGroup | WindowGroup

export const rowableFields = [
  'mini_split_tons',
  'new_windows_sliding_glass_sqft',
  'new_windows_sqft_each_window'
] as const

export type FieldRowable = typeof rowableFields[number]

export type FieldToRowable<K extends FieldRowable> = K extends 'mini'
  ? MiniSplit
  : K extends 'sli'
  ? SlidingGlassDoorGroup
  : K extends 'wi'
  ? WindowGroup
  : never

export interface WindowGroup {
  /**
   * Default is White. Including all windows sold without color until now.
   */
  readonly color: string
  /**
   * Unique ID for each window group.
   */
  readonly id: string
  /**
   * Sqft.
   */
  readonly size: number
  /**
   * Quantity of windows of this size.
   */
  readonly window: number
  /**
   * Giovanni only, old customers.
   */
  readonly windowType: string
}

export const newWindowGroup = (): WindowGroup => ({
  color: 'White',
  id: uuid(),
  size: 0,
  window: 1,
  windowType: 'standard'
})

export const isWindowGroup = (o: unknown): o is WindowGroup => {
  if (typeof o !== 'object' || o === null) {
    return false
  }

  const obj = o as WindowGroup

  return (
    typeof obj.id === 'string' &&
    (typeof obj.size === 'number' || typeof obj.size === 'string') &&
    (typeof obj.window === 'number' || typeof obj.window === 'string')
  )
}

export interface SlidingGlassDoorGroup {
  readonly id: string
  readonly size: string
  readonly total: number
}

export const newSlidingGlassDoorGroup = (): SlidingGlassDoorGroup => ({
  id: uuid(),
  size: '',
  total: 1
})

export const newMiniSplit = (): MiniSplit => ({
  id: uuid(),
  tons: 0,
  qty: 1
})

export interface CalcCol {
  /**
   * A unique key is needed.
   */
  readonly key: string
  /** e.g. 'amt*price' */
  readonly calc: (item: unknown) => number
  readonly label: string
  readonly type: 'calc'
  /**
   * Percentage of width. It should add up to exactly 90%, the remaining 10%
   * is for the delete button
   */
  readonly widthPer: number
}
export interface InputCol {
  readonly key: string
  readonly label: string
  readonly type: 'input'
  /**
   * Percentage of width. It should add up to exactly 90%, the remaining 10%
   * is for the delete button
   */
  readonly widthPer: number
}
export interface NumberInputCol {
  readonly key: string
  readonly label: string
  readonly type: 'number-input'
  /**
   * Percentage of width. It should add up to exactly 90%, the remaining 10%
   * is for the delete button
   */
  readonly widthPer: number
}
export interface OptCol {
  readonly key: string
  readonly label: string
  readonly opts: u.Opts
  readonly type: 'opts'
  /**
   * Percentage of width. It should add up to exactly 90%, the remaining 10%
   * is for the delete button
   */
  readonly widthPer: number
}
export type Col = CalcCol | InputCol | NumberInputCol | OptCol

export type ColType = Col['type']
export type Cols = readonly Col[]

export const initSlidingGlassDoorGroupCols: Cols = [
  {
    key: 'total',
    label: 'Doors',
    type: 'number-input',
    widthPer: 24
  },
  {
    key: 'size',
    label: 'Size (ft)',
    opts: slidingDoorsSize,
    type: 'opts',
    widthPer: 50
  },
  {
    key: 'sliding-total',
    calc: (item) => {
      const maybeSize =
        slidingToSize[
          (item as SlidingGlassDoorGroup).size as keyof typeof slidingToSize
        ]

      return (maybeSize || 0) * (item as SlidingGlassDoorGroup).total
    },
    label: 'Total (ft)',
    type: 'calc',
    widthPer: 16
  }
]

export interface MiniSplit {
  readonly id: string
  readonly qty: number
  readonly tons: number
}

export const initMiniSplitGroupCols: Cols = [
  {
    key: 'tons',
    label: 'Tons',
    opts: miniSplitSystems,
    type: 'opts',
    widthPer: 60
  },
  {
    key: 'qty',
    label: 'Qty',
    type: 'number-input',
    widthPer: 30
  }
]

export const thirdWindowColWidth = 36

export const windowGroupCols: Cols = [
  {
    key: 'window',
    label: 'Windows',
    type: 'number-input',
    widthPer: 20
  },
  {
    key: 'size',
    label: 'Size (Sqft)',
    type: 'input',
    widthPer: 22
  },
  {
    key: 'color',
    label: 'Color',
    opts: [],
    type: 'opts',
    widthPer: thirdWindowColWidth
  },
  {
    key: 'window-total',
    calc: (item) => {
      return (item as WindowGroup).window * (item as WindowGroup).size
    },
    label: 'Total',
    type: 'calc',
    widthPer: 12
  }
]

export const windowTypeCol = {
  key: 'windowType',
  label: 'Window Option',
  opts: [
    {
      label: 'Elite',
      value: 'elite'
    },
    {
      label: 'Elite w/Grid',
      value: 'elite-grid'
    },
    {
      label: 'Standard',
      value: 'standard'
    },
    {
      label: 'Standard w/Grid',
      value: 'standard-grid'
    }
  ],
  type: 'opts',
  widthPer: thirdWindowColWidth
} as const

export const windowGridCol = {
  key: 'isGrid'
}

export type RowableToCols = u.ReadonlyRecord<Cols, FieldRowable>
export const rowableToCols: RowableToCols = {
  mini_split_tons: initMiniSplitGroupCols,
  new_windows_sliding_glass_sqft: initSlidingGlassDoorGroupCols,
  new_windows_sqft_each_window: windowGroupCols
}

// #endregion Rowable

export const isYesNoOrYesNoEmpty = (field: unknown): field is CustomerField => {
  if (typeof field !== 'string') return false
  if (
    CustomerSchema[field as CustomerField] === YesNo ||
    CustomerSchema[field as CustomerField] === YesNoEmpty
  ) {
    return true
  }

  return false
}

interface TransactionData {
  key: string
  newValue: string
  oldValue: string
}

type TransactionCustomerData = Partial<Record<keyof Customer, TransactionData>>

export interface DBTransaction {
  customer: string
  data: TransactionCustomerData
  type: string
  uidUser: string
  user: string
}

export interface MediaData {
  type: 'img' | 'video'
  url: string
}

export type InitialCustomer = u.DeepReadonly<{
  customerAddress: string
  customerName: string
  customerPhone: string
  customerPhoneAlt: string
  ecohomeRep: string
  firebaseKey: string
  homeRep: string
  solarCompany: string
  solarRep: string
}>

/**
 * Main only via createdAt.
 */
export const insulationTypesOld: u.ReadonlyRecord<readonly string[]> = {
  APS: ['R-18', 'R-30', 'R-38', 'R-60'],
  CPS: ['R-17', 'R-18', 'R-23', 'R-30'],
  NPS: ['R-12', 'R-18', 'R-19', 'R-30'],
  NMPS: ['R-18', 'R-38', 'R-49'],
  TPS: ['R-18', 'R-30', 'R-38', 'R-60'],
  UPS: ['R-18', 'R-38', 'R-49']
}

export const NEW_INSULATION_TYPES_CUTOFF = moment('2023-09-18')

export interface CloserOrSetter {
  readonly label: string
  readonly value: string
}

/**
 * region Media Capture
 */

export interface PhotoFile {
  height: number
  isRawPhoto: boolean
  metadata?: {
    Orientation: number
    DPIHeight: number
    DPIWidth: number
  }
  path: string
  thumbnail?: Record<string, unknown>
  width: number
}
export interface MediaCapture {
  media: PhotoFile
  type: 'photo' | 'video'
}
export interface MediaCaptureWithBackup {
  description?: string
  id: string
  mediaCapture: MediaCapture
  oldMediaCapture?: MediaCapture
}

export interface SectionMediaCapture {
  data: MediaCaptureWithBackup[]
  title: MediaKind
}

export interface Debt {
  collections: string
  consolidationDebts: string
  created_at: number
  debtsPayments: string
  update_at: number
}
/**
 * endregion
 */
export type InvoiceCompany = 'trojan' | 'tello'

export interface InvoiceCompanyData {
  readonly address: string
  readonly license: string
  readonly name: string
}

export const companyInvoiceOptions: Record<InvoiceCompany, InvoiceCompanyData> =
  {
    tello: {
      address: '14310 Agave St\nMoreno Valley, CA 92553',
      license: '#886710',
      name: 'Tello Construction LLC'
    },
    trojan: {
      address: '311 S Valley View STE B103, Las Vegas Nevada',
      license: '#85060',
      name: 'Trojan Construction LLC'
    }
  }

export const solarCompanyToInvoiceCompanies: Partial<
  u.ReadonlyRecord<readonly InvoiceCompany[]>
> = {
  CPS: ['tello'],
  NPS: ['trojan']
}

export interface Invoice {
  readonly company: InvoiceCompany
  readonly customerID: string
  readonly date: number
  readonly efficiencyInfo: string
  readonly invoiceNumber: number
  readonly lastErr: string
  readonly objectID: string
  readonly sort_key: number
  readonly status: 'error' | 'pending' | 'uploading' | 'uploaded'
}

export type SimpleInvoice = Omit<
  Invoice,
  'date' | 'invoiceNumber' | 'objectID' | 'sort_key' | 'status'
>

export const isInvoice = (o: unknown): o is Invoice => {
  return (
    typeof o === 'object' &&
    o !== null &&
    typeof (o as Record<string, unknown>)['invoiceNumber'] === 'number'
  )
}

// TODO: New Name InvoiceEfficiency
export interface InvoiceEfficiencyInfo {
  readonly amount: string
  readonly description: string
  readonly id: string
  readonly quantity: string
  readonly selectEfficiency: string
}

export const isInvoiceEfficiencyInfo = (
  o: unknown
): o is InvoiceEfficiencyInfo => {
  if (typeof o !== 'object' || o === null) {
    return false
  }

  const obj = o as u.Dict<unknown>

  // TODO: better validation
  if (
    !obj['amount'] ||
    !obj['quantity'] ||
    !obj['selectEfficiency'] ||
    !obj['description'] ||
    !obj['id']
  ) {
    return false
  }
  return true
}

// The key is the Invoice ID
export type Invoices = u.Dict<Invoice>

export interface InvoiceUser {
  readonly email: string
  readonly date: number
}

export type InvoiceUsers = u.ReadonlyRecord<InvoiceUser>

export type FilteringQuery<T> = {
  column: keyof T
  query: string
}

export type SortQuery<T> = {
  column: keyof T
  kind: 'asc' | 'desc'
}

export const customerHasDataInField = (
  field: CustomerField,
  customer: Customer
): boolean => {
  const fieldType = CustomerSchema[field]

  if (fieldType === number) {
    return customer[field] > 0
  }
  if (fieldType === string) {
    return customer[field] !== ''
  }
  if (fieldType === YesNo) {
    return customer[field] === 'Yes'
  }
  if (fieldType === YesNoEmpty) {
    return customer[field] !== ''
  }
  if (fieldType === boolean) {
    return customer[field] === true
  }
  if (fieldType === Bit) {
    return customer[field] === 1
  }
  throw new TypeError(`Should be unreachable`)
}

export type RoofClaimingContractorCompany = 'ecohome'

export const RoofClaimingContractorToLabel: u.DeepReadonly<
  Record<RoofClaimingContractorCompany, string>
> = {
  ecohome: 'Eco Home Specialties'
}

export const RoofClaimingContractorHeaderText: u.DeepReadonly<
  Record<RoofClaimingContractorCompany, string[]>
> = {
  ecohome: [
    '(385) 277 0123',
    'Lic# 1110603',
    '19 E Citrus Ave. Suite 201',
    'Redlands, CA 92373'
  ]
}

export type PartialCustomer = u.Writable<Partial<Customer>>

// Partial<Record<keyof Customer, utils.ValueOf<Customer>> >

export const RoofClaimingContractData = {
  roof_claiming_date: number,
  roof_claiming_homeowner: string,
  roof_claiming_phone: string,
  roof_claiming_phone_alt: string,
  roof_claiming_email: string,
  roof_claiming_address: string,
  roof_claiming_city: string,
  roof_claiming_zip: string,
  roof_claiming_sum: string,
  roof_claiming_deductible: string,
  roof_claiming_shingle_manufacturer: string,
  roof_claiming_style: string,
  roof_claiming_color: string,
  roof_claiming_drip_color: string,

  roof_claiming_age: string,
  roof_claiming_layers: string,
  roof_claiming_predominate_pitch: string,

  roof_claiming_pipe_jack: string,
  roof_claiming_chimney_flashing: string,
  roof_claiming_chimney_cap: string,
  roof_claiming_digital_satellite: string,
  roof_claiming_gas_cap: string,
  roof_claiming_other_one: string,

  roof_claiming_turtle_vent: string,
  roof_claiming_ridge_vent: string,
  roof_claiming_turbine_vent: string,
  roof_claiming_power_attic_vent: string,
  roof_claiming_other_two: string,
  roof_claiming_other_three: string,
  //---- End Main Page inputs ----
  //---- Main Page checkbox ----
  roof_claiming_tear_off_layers_shingles: Bit,
  roof_claiming_install_felt: Bit,
  roof_claiming_close_valleys: Bit,
  roof_claiming_ridges_color_coordinated: Bit,
  roof_claiming_install_new_flashings: Bit,
  roof_claiming_replace_ventilation: Bit,
  roof_claiming_install_nails: Bit,
  roof_claiming_clean_job_waste_gutters: Bit,
  roof_claiming_two_year_workmanship_warranty: Bit,
  roof_claiming_magnetic_sweep_property: Bit,

  roof_claiming_notes: string,
  roof_claiming_documents_notes: string,

  roof_claiming_date_loss: number,
  roof_claiming_insurance_company: string,
  roof_claiming_claim: string,

  roof_claiming_client_sign: string,
  roof_claiming_client_sign_date: number,
  roof_claiming_company_rep_sign: string,
  roof_claiming_company_rep_sign_date: number,

  roof_claiming_printed_name: string,
  roof_claiming_completion_client_sign: string,
  roof_claiming_completion_client_sign_date: number,

  roof_claiming_company_contractor: string
}

export const roofClaimingFields = u.keys(RoofClaimingContractData)

export type RoofClaimingContractDataType = typeof RoofClaimingContractData
export type Lang = 'ENG' | 'ES'

export type LangText = u.DeepReadonly<Record<string, string>>

export interface ExampleImage {
  label: string
  source: string
}

export type PhoneValidatorResponse = {
  phone: string
  valid: boolean
  format: {
    international: string
    local: string
  }
  country: {
    code: string
    name: string
    prefix: string
  }
  location: string
  type: string
  carrier: string
}

export type ACPricing = u.r<{
  new_install: u.DictB<string>
  replacement: u.DictB<string>
}>

export type ACPricingStates = u.r<{
  california: {
    fresno: ACPricing
    victorville: ACPricing
  }
  nevada: {
    all: ACPricing
  }
}>

export type ACPrices = u.r<{
  city_permission: string
  remove_swamp_cooler: string
  //
  roof_electric: ACPricingStates
  roof_gas: ACPricingStates
  split_electric: ACPricingStates
  split_gas: ACPricingStates
}>

export const productOpts = ['Solar', 'HIL', 'RC'].map(
  (opt): u.Opt => ({
    label: opt,
    value: opt
  })
)
