import React from 'react'
import * as rn from 'react-native'

import * as c from '../../../common'
import * as common from '../../../common'
import * as r from '../../../react-utils'

import * as Mui from '@mui/material'
import * as MuiIcons from '@mui/icons-material'
import SimpleLineIcons from 'react-native-vector-icons/SimpleLineIcons'
import { kebabCase } from 'change-case'
import { ErrorBoundary } from 'react-error-boundary'
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom'

import * as cp from '../../comps'
import * as cpm from '../../../app/components'
import * as gs from '../../../app/gStyles'

import CheckGroup from '../../../app/screens/Customer/CheckGroup'
import CloserCalc from '../../../app/components/CloserCalc'
import MediaInput from '../../comps/MediaInput'
import NavMenu from '../../../app/components/NavMenu'
import NotFound from '../../comps/NotFound'
import Pad from '../../comps/Pad'
import MpuCalculator from '../../../app/components/ModalMpuCalculator'
import gStyles from '../../global.module.css'

import TotalCost from './TotalCost'
import styles from './Customer.module.css'

export interface CustomerProps {}

const smallScreenMediaQuery = window.matchMedia('(max-width: 1200px)')

export default React.memo<CustomerProps>(function Customer() {
  //#region styling
  const t = r.useTheme()
  const themedGStyles = gs.useGlobalStyles('canvas')
  const mStyles = gs.useThemedStyleSheet(themedStyles)

  React.useEffect(() => {
    // document.body.style.alignItems = 'center'
    // document.body.style.display = 'flex'
    // document.body.style.flexDirection = 'column'

    // document.body.style.paddingLeft = String((t.canvas.gap || 0) * 2) + 'px'
    // document.body.style.paddingRight = String((t.canvas.gap || 0) * 2) + 'px'
    // @ts-ignore
    !r.isSafari() && (document.body.style.zoom = 0.72)
    return (): void => {
      // document.body.style.alignItems = 'unset'
      // document.body.style.display = 'unset'
      // document.body.style.flexDirection = 'unset'
      // document.body.style.paddingLeft = 'unset'
      // document.body.style.paddingRight = 'unset'
      // @ts-ignore
      !r.isSafari() && (document.body.style.zoom = 1)
    }
  }, [t.canvas.gap])
  //#endregion styling

  const customerID = useParams<'customerID'>().customerID!

  //#region global
  const selectCustomer = React.useMemo(
    (): ReturnType<typeof common.makeSelectCustomer> =>
      common.makeSelectCustomer(),
    []
  )

  const selectCustomerArgs = React.useMemo((): common.SelectCustomerParams => {
    if (typeof customerID === 'string') {
      return { customerID }
    } else {
      return { customerID: '' }
    }
  }, [customerID])

  const { currProducts } = r.useProducts(customerID)

  const customer = common.useSelector(
    (_): common.Customer => selectCustomer(_, selectCustomerArgs)
  )
  //#endregion global

  //#region local
  const [closerCalcOpen, , toggleCloserCalc] = r.useBool(false)
  const [mpuCalculatorOpen, , toggleMpuCalculator] = r.useBool(false)
  const [, refresh] = React.useState(Math.random())
  const [navMenuOpen, setNavMenuOpen, toggleNavMenu] = r.useBool(
    !smallScreenMediaQuery.matches
  )
  const availNonCustomEfficiencies = React.useMemo(
    (): c.CustomerFields =>
      c.efficiencyKeys
        .filter((f) => !f.startsWith('custom'))
        .filter((f) => !c.shouldHideInput(f, customer)),
    [customer]
  )
  //#endregion local

  React.useEffect((): void => {
    for (const el of Array.from(
      window.document.querySelectorAll('div[aria-label]')
    )) {
      // @ts-ignore
      el.id = el.ariaLabel
    }
  }, [])

  const handleScreenSizeChange = React.useCallback((): void => {
    const isSmallScreen = smallScreenMediaQuery.matches

    if (isSmallScreen) {
      setNavMenuOpen(false)
    } else {
      setNavMenuOpen(true)
    }
    refresh(Math.random())
  }, [setNavMenuOpen])

  React.useEffect(() => {
    const handler = (e: Event) => {
      // @ts-ignore
      if (!e.target?.closest('div[aria-label="nav-menu"]')) {
        setNavMenuOpen(false)
      }
    }
    document.addEventListener('click', handler)

    return () => {
      document.removeEventListener('click', handler)
    }
  }, [setNavMenuOpen])

  React.useEffect(() => {
    smallScreenMediaQuery.addEventListener('change', handleScreenSizeChange)

    return () => {
      smallScreenMediaQuery.removeEventListener(
        'change',
        handleScreenSizeChange
      )
    }
  }, [handleScreenSizeChange])

  const location = useLocation()

  const navigate = useNavigate()

  React.useEffect((): common.EmptyFn => {
    if (customerID) {
      common.dispatch(common.subToSingleCustomer({ customerID }))

      return (): void => {
        common.dispatch(common.unSubFromSingleCustomer({ customerID }))
      }
    }
    return common.EMPTY_FN
  }, [customerID])

  // const lastTimeout = React.useRef(setTimeout(common.EMPTY_FN, 0))
  // const lastTimeoutColor = React.useRef(setTimeout(common.EMPTY_FN, 0))

  React.useEffect(() => {
    if (location.hash) {
      return
    }
    if (customer.date && typeof customer.date === 'number') {
      if (customer.date < 1679616000000) {
      }
    }
  }, [customer.date, location.hash])

  const handleAuditorReport = React.useCallback(() => {
    // const res = verifyEmptyFields(customer)
    // console.log('KEY INFO: ', JSON.stringify(res))
    // if (res.empty) {
    //   const targetScroll = document.getElementById(res.targetScroll)
    //   console.log(JSON.stringify(res))

    //   targetScroll?.scrollIntoView({
    //     behavior: 'smooth',
    //     block: isLargeEfficiency(res.targetScroll as keyof Customer)
    //       ? 'start'
    //       : 'center',
    //   })

    //   if (targetScroll) {
    //     targetScroll.style.borderRadius = '10px'
    //     targetScroll.style.backgroundColor = '#b2e2f2'

    //     setTimeout(() => {
    //       targetScroll.style.backgroundColor = 'unset'
    //       targetScroll.style.borderRadius = 'unset'
    //     }, 1800)
    //   }
    //   handleFillAlert()
    // }

    // if (!res.empty) {
    window.open(`/customers/${customerID}/auditor`, '_blank')?.focus()
    // }
  }, [customerID])

  const handleCustomerReport = React.useCallback(() => {
    // const res = common.verifyEmptyFields(customer)
    // if (!res.empty) {
    navigate(`/customers/${customerID}/customer`)
    //   return
    // }

    // const targetScroll = document.getElementById(res.targetScroll)

    // targetScroll?.scrollIntoView({
    //   behavior: 'smooth',
    //   block: common.isLargeEfficiency(res.targetScroll as keyof Customer)
    //     ? 'start'
    //     : 'center',
    // })
    // if (targetScroll) {
    //   targetScroll.style.borderRadius = '10px'
    //   targetScroll.style.backgroundColor = '#b2e2f2'

    //   setTimeout(() => {
    //     targetScroll.style.backgroundColor = 'unset'
    //     targetScroll.style.borderRadius = 'unset'
    //   }, 1800)
    // }
    // handleFillAlert()
  }, [customerID, navigate])

  const handleGoAssessmentMedia = React.useCallback((): void => {
    window.location.hash = 'assessment'
    toggleNavMenu()
  }, [toggleNavMenu])
  const handleGoToDocumentation = React.useCallback((): void => {
    window.location.hash = 'documentation'
    toggleNavMenu()
  }, [toggleNavMenu])
  const handleGoToRoofClaims = React.useCallback((): void => {
    window.location.hash = 'roof-claims'
    toggleNavMenu()
  }, [toggleNavMenu])
  const handleGoToSolarSurvey = React.useCallback((): void => {
    window.location.hash = 'solar-survey'
    toggleNavMenu()
  }, [toggleNavMenu])

  const handleCloserCalcFromNavMenu = React.useCallback(() => {
    toggleCloserCalc()
    toggleNavMenu()
  }, [toggleCloserCalc, toggleNavMenu])

  const handleNavToEfficiency = React.useCallback(
    (field: c.CustomerField) => {
      window.location.hash = field
      toggleNavMenu()
    },
    [toggleNavMenu]
  )

  const handleDeleteCustomer = React.useCallback((): void => {
    if (
      window.confirm(
        `Are you sure you want to delete the customer ${customer.customerName}`
      )
    ) {
      c.deleteCustomer(customerID)
    }
  }, [customer.customerName, customerID])

  // React.useEffect(() => {
  //   if (location.hash) {
  //     const efficiency = location.hash.slice(1)

  //     const targetScroll = document.getElementById(efficiency as string)

  //     const res = common.verifyEmptyFields(customer)
  //     if (res.empty) {
  //       console.log(JSON.stringify(res))
  //       clearTimeout(lastTimeout.current)
  //       lastTimeout.current = setTimeout(() => {
  //         targetScroll?.scrollIntoView({
  //           behavior: 'smooth',
  //           block: common.isLargeEfficiency(
  //             res.targetScroll as keyof common.Customer,
  //           )
  //             ? 'start'
  //             : 'center',
  //         })
  //         if (targetScroll) {
  //           targetScroll.style.borderRadius = '10px'
  //           targetScroll.style.backgroundColor = '#b2e2f2'
  //           clearTimeout(lastTimeoutColor.current)
  //           lastTimeoutColor.current = setTimeout(() => {
  //             targetScroll.style.backgroundColor = 'unset'
  //             targetScroll.style.borderRadius = 'unset'
  //             location.hash = ''
  //           }, 1600)
  //         }
  //         setFillAlert(true)
  //       }, 1200)
  //     }
  //   }
  //   const efficiency = location.hash.slice(1)

  //   const targetScroll = document.getElementById(efficiency as string)

  //   const res = common.verifyEmptyFields(customer)
  //   if (!res.empty) {
  //     clearTimeout(lastTimeout.current)
  //     return
  //   }

  //   clearTimeout(lastTimeout.current)
  //   lastTimeout.current = setTimeout(() => {
  //     targetScroll?.scrollIntoView({
  //       behavior: 'smooth',
  //       block: common.isLargeEfficiency(
  //         res.targetScroll as unknown as keyof Customer,
  //       )
  //         ? 'start'
  //         : 'center',
  //     })
  //     if (targetScroll) {
  //       targetScroll.style.borderRadius = '10px'
  //       targetScroll.style.backgroundColor = '#b2e2f2'
  //       clearTimeout(lastTimeoutColor.current)
  //       lastTimeoutColor.current = setTimeout(() => {
  //         targetScroll.style.backgroundColor = 'unset'
  //         targetScroll.style.borderRadius = 'unset'
  //         location.hash = ''
  //       }, 1600)
  //     }
  //     setFillAlert(true)
  //   }, 1200)
  // }, [customer, location])

  React.useEffect(() => {
    common.dispatch(common.efficiencyPricesAction())
  }, [])

  if (!customerID) {
    return <NotFound />
  }

  if (customer.deleted) {
    return (
      <div className={gStyles['content-holder']}>
        <h3>
          This customer ({customer.customerName || customerID}) has been deleted
        </h3>

        <code>{customerID}</code>

        <br />

        <Link to="/customers">Go back</Link>
      </div>
    )
  }

  return (
    <>
      {/* Breadcrumb height */}
      <cpm.Pad amt={32} />

      <div className={gStyles['content-holder']}>
        <rn.View style={themedGStyles.content}>
          <div className={gStyles['width-100']}>
            <cpm.Text bold on="canvas" size={24}>
              Customer Info
            </cpm.Text>
          </div>

          <cpm.Field customerID={customerID} field="createdAt" on="canvas" />
          <cpm.Field customerID={customerID} field="sort_key" on="canvas" />
          <cpm.Field customerID={customerID} field="customerName" on="canvas" />
          <cpm.Field
            customerID={customerID}
            field="customerEmail"
            on="canvas"
          />
          <cpm.Field
            customerID={customerID}
            field="customerAddress"
            on="canvas"
          />
          <cpm.Field
            customerID={customerID}
            field="customerPhone"
            on="canvas"
          />
          <cpm.Field
            customerID={customerID}
            field="customerPhoneAlt"
            on="canvas"
          />

          <cpm.Field customerID={customerID} field="ssn" on="canvas" />

          <cpm.Field customerID={customerID} field="solarCompany" on="canvas" />

          <cpm.Field customerID={customerID} field="products" on="canvas" />

          <cpm.Field
            customerID={customerID}
            field="main_panel_upgrade_installation_company"
            on="canvas"
          />
        </rn.View>

        {gs.rowGap12}

        <rn.View style={themedGStyles.content}>
          <div className={gStyles['width-100']}>
            <cpm.Text bold on="canvas" size={24}>
              House Info
            </cpm.Text>
          </div>

          <cpm.Field
            customerID={customerID}
            field="yearHomeBuilt"
            on="canvas"
          />

          <cpm.Field
            customerID={customerID}
            field="attic_square_footage"
            on="canvas"
          />
        </rn.View>

        {gs.rowGap12}

        <rn.View style={themedGStyles.content}>
          <div className={gStyles['width-100']}>
            <cpm.Text bold on="canvas" size={24}>
              Rep Info
            </cpm.Text>
          </div>

          <cpm.Field customerID={customerID} field="solarRep" on="canvas" />
          {common.IS_MAIN && (
            <cpm.Field customerID={customerID} field="solarEmail" on="canvas" />
          )}

          <cpm.Field customerID={customerID} field="homeRep" on="canvas" />

          <cpm.Field customerID={customerID} field="crm_id" on="canvas" />
        </rn.View>

        {gs.rowGap12}

        <rn.View style={themedGStyles.content}>
          <cpm.Text bold on="canvas" size={24}>
            Efficiencies
          </cpm.Text>
        </rn.View>

        {c.efficiencyKeys.map((field) => (
          <ErrorBoundary
            fallback={errorBoundaryFallback[field]}
            key={customerID + '/' + field}
          >
            <CheckGroup as="paper" customerID={customerID} field={field} />
          </ErrorBoundary>
        ))}

        <rn.View accessibilityLabel="exterior" style={themedGStyles.content}>
          <cpm.ConnectedInput
            customerID={customerID}
            field="globalNotes"
            on="canvas"
          />

          <TotalCost type="Customer" customerID={customerID} />
        </rn.View>
      </div>

      <MediaInput customerID={customerID} ofWhat="owner_house" />

      {c.entries(c.categoryToMediaKinds).map(([category, mediaKinds]) => (
        <React.Fragment key={customerID + '/' + category}>
          <div className={gStyles['content-holder']} id={kebabCase(category)}>
            <rn.View style={themedGStyles.content}>
              <cpm.Text bold on="canvas" size={26}>
                {category}
              </cpm.Text>
            </rn.View>
          </div>

          {mediaKinds.map((mk) => (
            <MediaInput
              customerID={customerID}
              key={`${customerID}/${category}/${mk}`}
              ofWhat={mk}
            />
          ))}
        </React.Fragment>
      ))}

      {c.CODENAME !== 'glow' && currProducts.includes('Solar') && (
        <>
          <div className={gStyles['content-holder']} id="solar-survey">
            <rn.View style={themedGStyles.content}>
              <cpm.Text bold on="canvas" size={26}>
                Solar Survey:
              </cpm.Text>
            </rn.View>
          </div>

          {c.solarSurveyMediaKinds.map((mediaKind) => (
            <MediaInput
              customerID={customerID}
              key={customerID + '/solarSurvey/' + mediaKind}
              ofWhat={mediaKind}
            />
          ))}

          <div className={gStyles['content-holder']}>
            <rn.View style={themedGStyles.content}>
              <cpm.ConnectedInput
                customerID={customerID}
                field="site_survey_notes"
                on="canvas"
              />
            </rn.View>

            <rn.View style={themedGStyles.separatorH} />
          </div>
        </>
      )}

      {currProducts.includes('RC') && (
        <>
          <div className={gStyles['content-holder']} id="roof-claims">
            <rn.View style={themedGStyles.content}>
              <cpm.Text bold on="canvas" size={26}>
                Roof Claims:
              </cpm.Text>
            </rn.View>
          </div>

          {c.roofClaimsMediaKinds.map((mediaKind) => (
            <MediaInput
              customerID={customerID}
              key={customerID + '/roofClaims/' + mediaKind}
              ofWhat={mediaKind}
            />
          ))}

          <div className={gStyles['content-holder']}>
            <rn.View style={themedGStyles.content}>
              <cpm.ConnectedInput
                customerID={customerID}
                field="roof_claiming_notes"
                on="canvas"
              />

              <rn.View style={themedGStyles.separatorH} />
            </rn.View>
          </div>
        </>
      )}

      {gs.rowGap16}

      <div className={gStyles['content-holder']}>
        <div style={gs.column}>
          <Mui.Typography onClick={handleAuditorReport} sx={sx['reportLink']}>
            Auditor Report
          </Mui.Typography>
          <Pad amt={20} />
          {common.IS_MAIN && customer.solarCompany !== 'affordable' && (
            <>
              <Mui.Typography onClick={toggleCloserCalc} sx={sx['reportLink']}>
                Commission Calculator
              </Mui.Typography>
              <Pad amt={20} />
            </>
          )}
          {common.IS_MAIN && customer.solarCompany !== 'affordable' && (
            <>
              <Mui.Typography
                onClick={handleCustomerReport}
                sx={sx['reportLink']}
              >
                Customer Report
              </Mui.Typography>
              <Pad amt={20} />
            </>
          )}

          {common.IS_MAIN && customer.solarCompany !== 'affordable' && (
            <>
              <Link
                className={styles['report-links']}
                to={`/customers/${customerID}/services`}
              >
                Energy Efficiencies Report{' '}
              </Link>

              <Pad amt={20} />
            </>
          )}

          <Mui.Typography onClick={toggleMpuCalculator} sx={sx['reportLink']}>
            MPU Calculator
          </Mui.Typography>

          <Pad amt={20} />

          <Mui.Typography
            onClick={handleDeleteCustomer}
            sx={sx['reportLinkRed']}
          >
            Delete customer
          </Mui.Typography>

          <Pad amt={20} />
        </div>
      </div>

      <Mui.Box sx={sx['breadCrumbBoxStyle']}>
        <Mui.Breadcrumbs aria-label="breadcrumb" sx={sx['breadCrumbsStyle']}>
          <Link to="/" style={{ textDecoration: 'none', color: 'white' }}>
            <MuiIcons.Home sx={{ mr: 0.5 }} fontSize="inherit" />
            Dashboard
          </Link>

          <Mui.Typography
            color="text.primary"
            component="span"
            sx={sx['textBreadCrumbStyle']}
          >
            <MuiIcons.Grain fontSize="inherit" sx={sx['grainIconStyle']} />
            <Mui.Typography sx={sx['grainIconTextStyle']}>
              {customer.customerName}
            </Mui.Typography>
          </Mui.Typography>
        </Mui.Breadcrumbs>
      </Mui.Box>

      <MpuCalculator
        onRequestClose={toggleMpuCalculator}
        visible={mpuCalculatorOpen}
      />

      <cp.Dialog
        onClose={toggleCloserCalc}
        open={closerCalcOpen}
        title="Commission Calculator"
      >
        <CloserCalc customerID={customerID} />
      </cp.Dialog>

      {/* <Mui.Modal onClose={handleCloseOldCustomerAlert} open={oldCustomerAlert}>
        <Mui.Box component={Mui.Paper} sx={sx['modalBoxStyle']}>
          <Mui.Alert severity="error" sx={sx['alertTextStyle']}>
            The creation date of this customer is before 03/24/2023 , If you
            need to update the price, click on “update prices” on the correspond
            efficiency
          </Mui.Alert>
          <Pad amt={30} />
          <Mui.Button
            onClick={handleCloseOldCustomerAlert}
            sx={sx['alertButtonStyle']}
            variant="contained"
          >
            OK
          </Mui.Button>
        </Mui.Box>
      </Mui.Modal> */}

      {!navMenuOpen && (
        <rn.TouchableOpacity onPress={toggleNavMenu} style={mStyles.menuIcon}>
          <SimpleLineIcons
            color={t.backdrop.color as string}
            name="menu"
            size={16}
          />
        </rn.TouchableOpacity>
      )}

      <NavMenu
        availNonCustomEfficiencies={availNonCustomEfficiencies}
        canHide={smallScreenMediaQuery.matches}
        customerID={customerID}
        hide={!navMenuOpen}
        onPressAuditor={handleAuditorReport}
        onPressAssessment={handleGoAssessmentMedia}
        onPressCommissionCalc={handleCloserCalcFromNavMenu}
        onPressDocumentation={handleGoToDocumentation}
        onPressEfficiency={handleNavToEfficiency}
        onPressRoofClaims={handleGoToRoofClaims}
        onPressSolarSurvey={handleGoToSolarSurvey}
      />
    </>
  )
})

// const Accordion = styled((props: AccordionProps) => (
//   <MuiAccordion disableGutters elevation={0} square {...props} />
// ))(({ theme }) => ({
//   border: `1px solid ${theme.palette.divider}`,
//   '&:not(:last-child)': {
//     borderBottom: 0,
//   },
//   '&:before': {
//     display: 'none',
//   },
// }))

// const AccordionSummary = styled((props: AccordionSummaryProps) => (
//   <MuiAccordionSummary
//     expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
//     {...props}
//   />
// ))(({ theme }) => ({
//   backgroundColor:
//     theme.palette.mode === 'dark'
//       ? 'rgba(255, 255, 255, .05)'
//       : 'rgba(0, 0, 0, .03)',
//   flexDirection: 'row-reverse',
//   '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
//     transform: 'rotate(90deg)',
//   },
//   '& .MuiAccordionSummary-content': {
//     marginLeft: theme.spacing(1),
//   },
// }))

// const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
//   padding: theme.spacing(2),
//   borderTop: '1px solid rgba(0, 0, 0, .125)',
// }))

const reportLink = {
  alignSelf: 'left',
  color: 'blue',
  cursor: 'pointer',
  margin: 0,
  textDecoration: 'none',
  '&:hover': {
    color: 'black'
  }
}
const sx = {
  alertTextStyle: {
    fontSize: {
      sm: '22px',
      xs: '16px'
    },
    textAlign: 'justify'
  },
  alertButtonStyle: {
    fontSize: {
      sm: '24px',
      xs: '18px'
    },
    marginTop: 'auto'
  },
  breadCrumbBoxStyle: {
    backgroundColor: '#154360',
    display: 'flex',
    justifyContent: 'center',
    left: 0,
    height: 32,
    position: 'fixed',
    top: 0,
    width: '100%'
  },
  breadCrumbsStyle: {
    alignSelf: 'flex-start',
    display: 'flex',
    width: {
      sm: '90%',
      xs: '100%'
    }
  },
  fillFieldsAlertStyle: {
    fontSize: '18px',
    width: '298px'
  },
  grainIconStyle: {
    color: 'white',
    mr: 0.5
  },
  grainIconTextStyle: {
    color: 'white',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    width: {
      sm: 'auto',
      xs: '100px'
    }
  },
  inputGroupStyle: {
    alignContent: { xs: 'center', sm: 'flex-start' },
    display: 'flex',
    flexDirection: 'column',
    overflowX: 'hidden',

    paddingTop: '40px',
    position: 'relative',
    paddingLeft: '24px',
    paddingRight: '24px',
    maxWidth: '100vw'
  },
  inputMediaWrapperStyle: {
    display: 'flex',
    height: '70px',
    width: '100%'
  },
  inputMediaWrapperHideStyle: {
    display: {
      lg: 'none',
      xs: 'flex'
    },
    height: '70px',
    width: '100%'
  },
  modalBoxStyle: {
    alignItems: 'center',
    borderRadius: '10px',
    display: 'flex',
    flexDirection: 'column',
    left: '50%',
    padding: '20px',
    position: 'absolute',
    top: '50%',
    transform: 'translate(-50%, -50%)',
    width: {
      sm: '500px',
      xs: '320px'
    }
  },
  textBreadCrumbStyle: {
    alignItems: 'center',
    display: 'flex'
  },
  reportLink,
  reportLinkRed: {
    ...reportLink,
    alignSelf: 'left',
    color: 'red',
    cursor: 'pointer',
    margin: 0,
    textDecoration: 'none',
    '&:hover': {
      color: 'black'
    }
  },
  wrapperCustomerLgHeighAutoStyle: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    height: 'auto',
    overflowY: 'auto',
    position: 'relative',
    width: '100%'
  },
  wrapperCustomerLgHeightStyle: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    height: {
      lg: 'calc( 100% - 70px )',
      xs: 'auto'
    },
    overflowY: 'auto',
    position: 'relative',
    width: '100%'
  }
}

const errorBoundaryFallback = (() => {
  const res: Partial<Record<c.CustomerField, React.ReactElement>> = {}
  for (const field of c.efficiencyKeys) {
    res[field] = (
      <div
        style={{
          padding: 24,
          borderColor: '#CCCCCC',
          borderStyle: 'solid',
          borderWidth: 1
        }}
      >
        <span>
          Something went wrong with the "{c.getFieldLabel(field)}" efficiency,
          please report to Dev Dpt.
        </span>
      </div>
    )
  }
  return res as Record<c.CustomerField, React.ReactElement>
})()

const themedStyles = gs.ThemedStyleSheet.create((t) => ({
  menuIcon: {
    ...gs.shadowFull,
    backgroundColor: t.backdrop.backgroundColor,
    borderRadius: Number.MAX_SAFE_INTEGER,
    // @ts-ignore 'fixed' not available on mobile
    position: (r.isWeb ? 'fixed' : 'absolute') as 'absolute',
    right: Number(t.canvas.gap) * 2,
    bottom: t.canvas.gap,
    padding: 32
  }
}))
