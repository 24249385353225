import React from 'react'

import * as c from '../../../common'
import * as r from '../../../react-utils'

import * as Mui from '@mui/material'
import * as MuiIcons from '@mui/icons-material'
import moment from 'moment'
import useMediaQuery from '@mui/material/useMediaQuery'
import { Link, useNavigate } from 'react-router-dom'
import { InstantSearch, SearchBox, connectHits } from 'react-instantsearch-dom'
import { push, update } from 'firebase/database'

import * as fire from '../../fire'

import CustomerInfo from '../../../app/screens/Customer/CustomerInfo'

import CustomerCard from '../CustomerCard'
import Dialog from '../Dialog'
import EditCustomerButton from '../EditCustomerButton'
import NavBar from '../NavBar'
import ToastAlert from '../ToastAlert'

import Actions from './Actions'
import styles from './CustomerList.module.css'

export interface CustomerListProps {}

export default React.memo<CustomerListProps>(function CustomerList() {
  const isDark = useMediaQuery('(prefers-color-scheme: dark)')

  const isMounted = r.useIsMounted()
  const navigate = useNavigate()

  //#region addCustomer
  const [addCustomerOpen, setAddCustomerOpen] = React.useState(false)
  const [closerWhenAdding, handleCloserWhenAdding] = React.useState('n/a')
  const [customerNameWhenAdding, handleCustomerNameWhenAdding] =
    React.useState('')
  const [customerAddressWhenAdding, handleCustomerAddressWhenAdding] =
    React.useState('')
  const [customerPhoneWhenAdding, handleCustomerPhoneWhenAdding] =
    React.useState('')
  const [customerPhoneAltWhenAdding, handleCustomerPhoneAltWhenAdding] =
    React.useState('')
  const [solarCompanyWhenAdding, handleSolarCompanyWhenAdding] =
    React.useState('')
  const [productsWhenAdding, handleProductsWhenAdding] = React.useState('')
  const [setterWhenAdding, handleSetterWhenAdding] = React.useState('n/a')
  const [installerWhenAdding, setInstallerWhenAdding] =
    React.useState('To Be Decided')
  const [crmIdWhenAdding, setCrmIDWhenAdding] = React.useState('')

  const [shouldShowAsterisks, setShouldShowAsterisks] = React.useState(false)
  const [loading, setLoading] = React.useState(false)

  const handleCustomerSubmit = React.useCallback((): void => {
    if (
      !closerWhenAdding ||
      !customerNameWhenAdding ||
      !customerAddressWhenAdding ||
      !customerPhoneWhenAdding ||
      !solarCompanyWhenAdding ||
      !setterWhenAdding
    ) {
      setShouldShowAsterisks(true)
      return
    }
    setLoading(true)
    ;(async (): Promise<void> => {
      try {
        const normalizedPhone = c.normalizePhoneNumber(customerPhoneWhenAdding)

        if (normalizedPhone.length !== 10) {
          throw new Error(
            'Main phone number should be 10 digits long. E.g. (786) 786 - 7867'
          )
        }

        if (!c.isValidUSPhoneNumber(normalizedPhone)) {
          throw new Error(
            `Invalid main phone number Double-check the area code (${normalizedPhone.slice(
              0,
              3
            )})`
          )
        }

        const normalizedPhoneAlt = c.normalizePhoneNumber(
          customerPhoneAltWhenAdding
        )

        if (normalizedPhoneAlt.length > 0) {
          if (normalizedPhoneAlt.length !== 10) {
            throw new Error(
              'Alternative phone number should be 10 digits long. E.g. (786) 786 - 7867'
            )
          }

          if (!c.isValidUSPhoneNumber(normalizedPhoneAlt)) {
            throw new Error(
              `Invalid alternative phone number.Double-check the area code (${normalizedPhoneAlt.slice(
                0,
                3
              )})`
            )
          }
        }

        const customerRef = await push(fire.customersDB, {})

        if (customerRef.key === null)
          throw new ReferenceError('customerRef.key === null')

        const newCustomer = c.createEmptyCustomer({
          crm_id: crmIdWhenAdding,
          customerAddress: customerAddressWhenAdding,
          customerName: customerNameWhenAdding,
          customerPhone: customerPhoneWhenAdding,
          customerPhoneAlt: customerPhoneAltWhenAdding,
          ecohomeRep: closerWhenAdding,
          firebaseKey: customerRef.key,
          main_panel_upgrade_installation_company: installerWhenAdding,
          products: productsWhenAdding,
          solarCompany: solarCompanyWhenAdding,
          solarRep: setterWhenAdding
        })

        await update(customerRef, newCustomer)

        c.searchIndex.saveObject({
          crm_id: crmIdWhenAdding,
          customerAddress: newCustomer.customerAddress,
          customerName: newCustomer.customerName,
          date: newCustomer.sort_key,
          deleted: false,
          objectID: newCustomer.keyID,
          solarCompany: newCustomer.solarCompany || '',
          solarRep: newCustomer.solarRep || ''
        })

        navigate(customerRef.key)
      } catch (e) {
        console.log(e)
        alert(`Error: ${c.processErr(e)}`)
      } finally {
        setLoading(false)
      }
    })()
  }, [
    closerWhenAdding,
    customerNameWhenAdding,
    customerAddressWhenAdding,
    customerPhoneWhenAdding,
    solarCompanyWhenAdding,
    setterWhenAdding,
    customerPhoneAltWhenAdding,
    crmIdWhenAdding,
    installerWhenAdding,
    productsWhenAdding,
    navigate
  ])
  //#endregion addCustomer

  const [customerIDBeingDeleted, setCustomerIDBeingDeleted] = React.useState('')
  const [open, setOpen] = React.useState(false)
  const container = React.useRef<HTMLDivElement>(document.createElement('div'))

  const dispatch = c.useDispatch()

  const clearCustomerIDToBeDeleted = React.useCallback(() => {
    setCustomerIDBeingDeleted('')
  }, [])

  const handleCustomerDeletion = React.useCallback((customerID: string) => {
    if (
      window.confirm(`Are you sure you want to delete customer this customer?`)
    ) {
      c.updateCustomer(customerID, {
        deleted: true
      })
      setCustomerIDBeingDeleted(customerID)
    }
  }, [])

  const undoDeleteCustomer = React.useCallback(() => {
    if (!customerIDBeingDeleted) return
    c.updateCustomer(customerIDBeingDeleted, {
      deleted: false
    })
    setCustomerIDBeingDeleted('')
  }, [customerIDBeingDeleted])

  const handleOpen = React.useCallback(
    (e) => {
      setOpen(!!e.target.value)
    },
    [setOpen]
  )

  const closeResults = React.useCallback(() => {
    if (!isMounted()) {
      return
    }
    setOpen(false)
  }, [isMounted])

  const toggleAddCustomer = React.useCallback(() => {
    setAddCustomerOpen((currentlyOpened) => {
      const newlyOpened = !currentlyOpened

      if (newlyOpened) {
        // @ts-ignore
        !r.isSafari() && (document.body.style.zoom = 0.72)
      } else {
        // @ts-ignore
        !r.isSafari() && (document.body.style.zoom = 1)
      }

      return newlyOpened
    })
  }, [])

  const complexCustomers = c.useSelector(c.selectAllCustomers)

  const customers = React.useMemo(
    (): c.SimpleCustomer[] => complexCustomers.map(c.simplifyCustomer),
    [complexCustomers]
  )

  const authCheckTimeoutRef = React.useRef<NodeJS.Timeout>(
    setTimeout(c.EMPTY_FN, 0)
  )
  React.useEffect(() => {
    if (customers.length > 0) {
      return c.EMPTY_FN
    }
    authCheckTimeoutRef.current = setTimeout(() => {
      if (customers.length === 0 && Boolean(fire.auth.currentUser)) {
        // If no customers have loaded by 10 seconds, probably needs to re-auth
        fire.auth.signOut()
      }
    }, 10000)

    return () => {
      clearTimeout(authCheckTimeoutRef.current)
    }
  }, [customers])

  const onEndReached = React.useCallback((): void => {
    if (customers.length !== c.MAX_CUSTOMERS_AT_ONCE) {
      dispatch(c.requestedMoreCustomers())
    }
  }, [customers.length, dispatch])

  React.useEffect((): c.VoidFn => {
    dispatch(c.subToCustomers())

    return (): void => {
      dispatch(c.unSubFromCustomers())
    }
  }, [dispatch])

  React.useEffect(() => {
    const addMoreCustomers = () => {
      const percent90height = document.body.offsetHeight * 0.95

      if (window.scrollY + window.innerHeight >= percent90height) {
        onEndReached()
      }
    }
    document.addEventListener('scroll', addMoreCustomers)

    return () => {
      document.removeEventListener('scroll', addMoreCustomers)
    }
  }, [onEndReached])

  const handleDragStart = React.useCallback((e) => {
    e.preventDefault()
  }, [])

  const searchInputRef = React.useRef<HTMLInputElement>(
    document.createElement('input')
  )

  const handleClickActiveInput = React.useCallback(() => {
    searchInputRef.current.focus()
  }, [])

  return (
    <>
      <InstantSearch
        indexName="prod_ecohome"
        refresh={true}
        searchClient={c.searchClient}
      >
        <Mui.Box sx={sx['root']}>
          <Mui.Box sx={sx['padBetweenHeaderAndContent']} />

          <Mui.Box sx={sx['container']}>
            <Mui.Box ref={container} sx={sx['customers']}>
              <Mui.TableContainer
                component={Mui.Paper}
                sx={sx['tableContainer']}
              >
                <Mui.Table
                  aria-label="sticky table"
                  stickyHeader
                  sx={isDark ? sx['tableDark'] : sx['table']}
                >
                  <Mui.TableHead>
                    <Mui.TableRow>
                      <Mui.TableCell style={sx['noZ']}>
                        <Mui.Typography color={isDark ? c.white : c.black}>
                          Last update
                        </Mui.Typography>
                      </Mui.TableCell>
                      <Mui.TableCell align="center" style={sx['noZ']}>
                        <Mui.Typography color={isDark ? c.white : c.black}>
                          Name
                        </Mui.Typography>
                      </Mui.TableCell>
                      <Mui.TableCell align="center" style={sx['noZ']}>
                        <Mui.Typography color={isDark ? c.white : c.black}>
                          Address
                        </Mui.Typography>
                      </Mui.TableCell>
                      <Mui.TableCell align="center" style={sx['noZ']}>
                        <Mui.Typography color={isDark ? c.white : c.black}>
                          Rep Name
                        </Mui.Typography>
                      </Mui.TableCell>
                      <Mui.TableCell align="center" style={sx['noZ']}>
                        <Mui.Typography color={isDark ? c.white : c.black}>
                          Solar Company
                        </Mui.Typography>
                      </Mui.TableCell>
                      <Mui.TableCell align="center" style={sx['noZ']}>
                        <Mui.Typography color={isDark ? c.white : c.black}>
                          Actions
                        </Mui.Typography>
                      </Mui.TableCell>
                    </Mui.TableRow>
                  </Mui.TableHead>
                  <Mui.TableBody>
                    {customers.map((customer) => (
                      <React.Fragment key={customer.objectID}>
                        <Mui.TableRow
                          sx={!customer.deleted ? sx['tableRow'] : sx['hidden']}
                        >
                          <Mui.TableCell
                            color={isDark ? c.white : c.black}
                            component="th"
                            scope="row"
                          >
                            <Mui.Typography color={isDark ? c.white : c.black}>
                              {moment(customer.sort_key * -1).format(
                                c.readableDateFormat
                              )}
                            </Mui.Typography>
                          </Mui.TableCell>
                          <Mui.TableCell align="center">
                            <Mui.Typography color={isDark ? c.white : c.black}>
                              {customer.customerName}
                            </Mui.Typography>
                          </Mui.TableCell>
                          <Mui.TableCell align="center">
                            <Mui.Typography color={isDark ? c.white : c.black}>
                              {customer.customerAddress}
                            </Mui.Typography>
                          </Mui.TableCell>
                          <Mui.TableCell
                            align="center"
                            color={isDark ? c.white : c.black}
                          >
                            <Mui.Typography color={isDark ? c.white : c.black}>
                              {customer.solarRep}
                            </Mui.Typography>
                          </Mui.TableCell>
                          <Mui.TableCell
                            align="center"
                            color={isDark ? c.white : c.black}
                          >
                            <Mui.Typography color={isDark ? c.white : c.black}>
                              {c.companyToLabel[customer.solarCompany]}
                            </Mui.Typography>
                          </Mui.TableCell>
                          <Actions
                            customerID={customer.objectID}
                            onCustomerDeletion={handleCustomerDeletion}
                          />
                        </Mui.TableRow>
                      </React.Fragment>
                    ))}
                  </Mui.TableBody>
                </Mui.Table>
              </Mui.TableContainer>
              {customers.map((customer) => (
                <CustomerCard
                  customer={customer}
                  key={customer.key}
                  onCustomerDeletion={handleCustomerDeletion}
                />
              ))}
            </Mui.Box>

            <Mui.ClickAwayListener onClickAway={closeResults}>
              <Mui.Box sx={open ? sx['boxSearchResults'] : sx['hidden']}>
                <SearchResults />
              </Mui.Box>
            </Mui.ClickAwayListener>
          </Mui.Box>

          <Mui.Stack sx={sx['header']}>
            <NavBar>
              <Search onDragStart={handleDragStart}>
                <MuiIcons.Search
                  onClick={handleClickActiveInput}
                  sx={sx['searchIcon']}
                  width={20}
                />

                <SearchBox
                  className={styles['search-box']}
                  inputRef={searchInputRef}
                  onChange={handleOpen}
                />
              </Search>
            </NavBar>
          </Mui.Stack>
        </Mui.Box>
      </InstantSearch>

      <EditCustomerButton
        bottom={15}
        handleAddCustomerModal={toggleAddCustomer}
        right={15}
      />

      <Dialog
        onClose={toggleAddCustomer}
        opaque
        open={addCustomerOpen}
        title="Add Customer"
      >
        <CustomerInfo
          customerKey=""
          hide={false}
          inputsEditable={true}
          //
          closerWhenAdding={closerWhenAdding}
          onChangeCloserWhenAdding={handleCloserWhenAdding}
          customerNameWhenAdding={customerNameWhenAdding}
          onChangeCustomerNameWhenAdding={handleCustomerNameWhenAdding}
          customerAddressWhenAdding={customerAddressWhenAdding}
          onChangeCustomerAddressWhenAdding={handleCustomerAddressWhenAdding}
          customerPhoneWhenAdding={customerPhoneWhenAdding}
          onChangeCustomerPhoneWhenAdding={handleCustomerPhoneWhenAdding}
          customerPhoneAltWhenAdding={customerPhoneAltWhenAdding}
          onChangeCustomerPhoneAltWhenAdding={handleCustomerPhoneAltWhenAdding}
          solarCompanyWhenAdding={solarCompanyWhenAdding}
          onChangeSolarCompanyWhenAdding={handleSolarCompanyWhenAdding}
          productsWhenAdding={productsWhenAdding}
          onChangeProductsWhenAdding={handleProductsWhenAdding}
          setterWhenAdding={setterWhenAdding}
          installerWhenAdding={installerWhenAdding}
          onChangeInstallerWhenAdding={setInstallerWhenAdding}
          onChangeSetterWhenAdding={handleSetterWhenAdding}
          crmIDWhenAdding={crmIdWhenAdding}
          onChangeCrmIDWhenAdding={setCrmIDWhenAdding}
          loading={loading}
          onSaveWhenAdding={handleCustomerSubmit}
          shouldShowAsterisks={shouldShowAsterisks}
        />
      </Dialog>

      <ToastAlert
        duration={4000}
        handleClose={clearCustomerIDToBeDeleted}
        message="Customer Deleted"
        onClick={undoDeleteCustomer}
        open={customerIDBeingDeleted ? true : false}
        severity="info"
        stopTime={customerIDBeingDeleted ? false : true}
        type="undoButton"
      />
    </>
  )
})

const SearchResults = connectHits(({ hits }) => {
  const isDark = useMediaQuery('(prefers-color-scheme: dark)')
  // Algolia re-renders unnecessarily thus necessitating this useMemo() here
  const sanitized = React.useMemo(() => {
    const validated = hits.map(c.validateCustomer).filter((c) => c.ok)

    return (
      validated
        .map((r) => r.sanitized)
        // A typical ID is 20 characters long
        .filter((c) => c.key.length >= 18)
        .filter((c) => !c.deleted)
    )
  }, [hits])

  React.useEffect(() => {
    c.dispatch(
      c.receivedSimpleCustomers({
        customers: sanitized.map(c.simplifyCustomer)
      })
    )
  }, [sanitized])

  return (
    <Mui.Box
      component={Mui.Paper}
      elevation={9}
      sx={
        sanitized.length >= 9
          ? isDark
            ? sx['boxResultsDark']
            : sx['boxResults']
          : isDark
          ? sx['boxResultsAutoHeightDark']
          : sx['boxResultsAutoHeight']
      }
    >
      {sanitized.map((customer) => (
        <Link
          key={customer.key}
          style={!customer.deleted ? linkStyle : hiddenStyle}
          to={`/customers/${customer.objectID}`}
        >
          <Mui.Box
            component={Mui.Paper}
            elevation={6}
            sx={isDark ? sx['resultDark'] : sx['result']}
          >
            <Mui.Box sx={sx['boxResultsItemText']}>
              <Mui.Typography
                color={isDark ? c.white : c.black}
                sx={sx['typographyResultsItemText']}
              >
                {customer.customerName}
              </Mui.Typography>
            </Mui.Box>

            <Mui.Box sx={sx['textRow']}>
              <Mui.Typography
                color={isDark ? c.white : c.black}
                sx={sx['typographyResultsItemText']}
              >
                {customer.customerAddress}
              </Mui.Typography>
            </Mui.Box>
            <Mui.Box sx={sx['boxSolarRepText']}>
              <Mui.Typography
                color={isDark ? c.white : c.black}
                sx={sx['typographyResultsItemText']}
              >
                {customer.solarRep}
              </Mui.Typography>
            </Mui.Box>
          </Mui.Box>
        </Link>
      ))}
    </Mui.Box>
  )
})

const boxResultsBase = {
  borderRadius: '10px',
  display: 'flex',
  flexDirection: 'column',
  left: '50%',
  overflowY: 'auto',
  padding: '5px',
  position: 'fixed',
  top: '96px',
  transform: 'translate(-50%, 0%)'
} as const
const rootBase = {
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'column',
  height: 'auto',
  width: '100vw'
}
const resultBase = {
  alignItems: 'center',
  display: 'flex',
  height: '50px',
  justifyContent: 'space-around',
  marginBottom: '5px',
  width: '100%'
}
const sx = {
  boxIcon: {
    backgroundColor: '#3b8ad9',
    borderRadius: '5px 0 0px 5px',
    display: 'flex',
    height: '100%',
    justifyContent: 'center',
    position: 'absolute',
    width: '30px'
  },
  boxIconDark: {
    backgroundColor: '#474747',
    borderRadius: '5px 0 0px 5px',
    display: 'flex',
    height: '100%',
    justifyContent: 'center',
    position: 'absolute',
    width: '30px'
  },
  boxInputSearch: {
    alignSelf: 'center',
    display: 'flex',
    height: '100%',
    width: '80%'
  },
  root: { ...rootBase },
  rootDark: {
    ...rootBase,
    backgroundColor: c.dark.backdrop.backgroundColor as string
  },
  container: {
    height: 'auto',
    margin: { lg: '0 auto' },
    overflowY: 'auto',
    position: 'relative',
    width: { lg: '90vw', xs: '100%' }
  },
  boxSearchResults: { display: 'flex' },
  boxResultsAutoHeight: {
    ...boxResultsBase,
    backgroundColor: c.light.canvas.backgroundColor as string,
    height: 'auto'
  },
  boxResultsAutoHeightDark: {
    ...boxResultsBase,
    backgroundColor: c.dark.canvas.backgroundColor as string,
    height: 'auto'
  },
  boxResults: {
    ...boxResultsBase,
    backgroundColor: c.light.canvas.backgroundColor as string,
    height: '480px'
  },
  boxResultsDark: {
    ...boxResultsBase,
    backgroundColor: c.dark.canvas.backgroundColor as string,
    height: '480px'
  },
  boxResultsItemText: {
    alignItems: 'center',
    display: 'flex',
    height: '100%',
    justifyContent: 'center',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },
  padBetweenHeaderAndContent: {
    display: 'flex',
    height: '70px',
    width: '100%'
  },
  boxSolarRepText: {
    alignItems: 'center',
    display: { xs: 'none', md: 'flex' },
    height: '100%',
    justifyContent: 'center',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },
  customers: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    width: '100%'
  },
  hidden: { display: 'none' },
  noZ: { zIndex: 'inherit', backgroundColor: 'transparent' },
  searchIcon: { ':hover': { cursor: 'text' } },
  header: {
    height: '70px',
    position: 'fixed',
    width: '100%'
  },
  result: {
    ...resultBase,
    backgroundColor: c.light.backdrop.backgroundColor as string
  },
  resultDark: {
    ...resultBase,
    backgroundColor: c.dark.backdrop.backgroundColor as string
  },
  table: { minWidth: 600 },
  tableDark: {
    backgroundColor: c.dark.canvas.backgroundColor as string,
    minWidth: 600
  },
  textRow: {
    alignItems: 'center',
    display: 'flex',
    height: '100%',
    justifyContent: 'center'
  },
  typographyResultsItemText: {
    overflow: 'hidden',
    textAlign: 'center',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    width: { sm: '180px', xs: '150px' }
  },
  tableContainer: { display: { md: 'block', xs: 'none' } },
  tableRow: { display: 'table-row' }
}

const linkStyle = {
  textDecoration: 'none'
}

const hiddenStyle = {
  display: 'none'
}

const Search = Mui.styled('div')(({ theme }) => ({
  alignItems: 'center',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: Mui.alpha(theme.palette.common.white, 0.15),
  display: 'flex',
  flexDirection: 'row',
  marginRight: theme.spacing(2),
  marginLeft: 0,
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(3),
    width: 'auto'
  }
}))
